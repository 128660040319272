import styled from '@emotion/styled';
import { css } from '@emotion/react';

const efficiencyTitle = ({ theme }) => css`
  color: ${theme.colors.white};
  font-size: ${theme.typography.xl2};
  font-weight: 600;
`;

const depositionSectionStyles = () => css`
  margin-top: 72px;
`;

const depositionTitleStyles = ({ theme }) => css`
  font-size: ${theme.typography.xl2};
  color: ${theme.colors.white};
  font-weight: bold;
  margin-bottom: 25px;
`;

const depositionCardStyles = ({ theme }) => css`
  display: flex;

  img {
    border-radius: 50%;
    width: 68px;
    height: 68px;
    object-fit: cover;
    margin-right: 34px;
  }
  svg {
    transition: all 0.3s cubic-bezier(0.25, 0.39, 0.39, 2.01);
  }

  svg:hover {
    transform: scale(1.2);
    transition: all 0.3s cubic-bezier(0.25, 0.39, 0.39, 2.01);
  }
`;

const depositionInfoStyles = ({ theme }) => css``;

const depositionNameStyles = ({ theme }) => css`
  font-size: ${theme.typography.sm};
  color: ${theme.colors.blue};
  font-weight: bold;

  svg {
    margin-left: 16px;
  }
`;

const depositionInfoTextStyles = ({ theme }) => css`
  font-size: 10px;
  color: ${theme.colors.white};
  font-weight: 100;
  opacity: 60%;
  margin: 5px 0;
`;

const alignCenterStyles = () => css`
  display: flex;
  align-items: center;
`;

const quotesImgStyles = () => css`
  margin-top: 20px;
`;

const defaultSectionStyles = () => css`
  margin: 30px 0 200px 0;
`;

export const EfficiencyTitle = styled('h1')(efficiencyTitle);

export const DepositionSection = styled('div')(depositionSectionStyles);
export const DepositionTitle = styled('h1')(depositionTitleStyles);
export const DepositionCard = styled('div')(depositionCardStyles);
export const DepositionInfo = styled('div')(depositionInfoStyles);
export const DepositionInfoText = styled('h1')(depositionInfoTextStyles);
export const DepositionName = styled('h1')(
  depositionNameStyles,
  alignCenterStyles
);
export const Quotes = styled('img')(quotesImgStyles);
export const DefaultSection = styled('div')(defaultSectionStyles);

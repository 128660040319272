import styled from '@emotion/styled';
import { css } from '@emotion/react';
import Grid from '@material-ui/core/Grid';
import { motion } from 'framer-motion';

import chevronDown from '~/assets/img/chevron-dawn.png';

import * as U from '~/styles/utilities';

const wrapperStyles = () => css`
  position: relative;
`;

const wrapperBannerStyles = ({ theme }) => css`
  min-height: 530px;

  @media (min-width: ${theme.breaks.large}) {
    margin-top: 200px;
  }

  @media (max-width: ${theme.breaks.medium}) {
    min-height: 90vh;
  }

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 100px;
`;

const contentBannerStyles = ({ theme }) => css`
  display: flex;
  align-items: flex-start;
  text-align: left;
`;

const marginTextBannerStyles = ({ theme }) => css`
  @media (min-width: ${theme.breaks.large}) {
    margin-top: 80px;
  }
`;

const imageBannerStyles = ({ theme }) => css`
  width: 100%;
  object-fit: cover;
  height: 376px;
  border-radius: 10px;
  transform: rotateY(-10deg);

  @media (min-width: 959px) {
    margin-left: 180px;
  }

  @media (max-width: ${theme.breaks.medium1}) {
    margin-bottom: 50px;
    height: 500px;
  }

  @media (max-width: ${theme.breaks.medium}) {
    height: 376px;
  }

  @media (max-width: ${theme.breaks.small}) {
    height: 250px;
  }
`;
const titleStyles = ({ theme }) => css`
  color: ${theme.colors.white};
  line-height: 43px;
`;

const learnSectionStyles = () => css`
  margin: 75px 0 110px 0;
`;

const learnCardStyles = ({ theme }) =>
  css`
    padding-right: 80px;
    margin-bottom: 120px;

    @media (max-width: ${theme.breaks.medium}) {
      padding: 0;
    }

    img {
      width: 132px;
      height: 32px;
      object-fit: contain;

      opacity: 70%;
      filter: grayscale(1);

      &:not(:last-of-type) {
        margin-right: 10px;
      }
    }
  `;

const wrapperWhyTitleStyles = () => css`
  display: flex;
  align-items: flex-start;
  margin-bottom: 22px;

  svg {
    margin-right: 16px;
  }
`;

const titleWhyStyles = ({ theme }) => css`
  color: ${theme.colors.lightblue};
  font-size: ${theme.typography.sm};
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 2.59px;
  width: 150px;
`;

const titleBannerStyles = ({ theme }) => css`
  font-size: 40px;
  color: ${theme.colors.lightblue};
  font-weight: bold;
  line-height: 39px;
  letter-spacing: 11.29px;
  opacity: 1;
  text-transform: uppercase;

  @media (max-width: ${theme.breaks.medium}) {
    font-size: 30px;
    line-height: 34px;
  }
`;

const textTopBannerStyles = ({ theme }) => css`
  font-size: 16px;
  color: ${theme.colors.lightblue};
  font-weight: 500;
  line-height: 43px;

  @media (max-width: ${theme.breaks.medium}) {
    font-size: 30px;
    line-height: 21px;
  }
`;

const gridBanner = () => css`
  @media (max-width: 1280px) {
    flex-direction: column-reverse;
  }
`;

const cardStyles = ({ theme }) => css`
  background: ${theme.colors.gray5};
  border: 1px solid ${theme.colors.gray6};
  border-radius: 4px;
  opacity: 1;

  p {
    color: ${theme.colors.white};
    font-size: ${theme.typography.xs};
    opacity: 60%;
    margin-bottom: 65px;
    font-weight: 100;
    line-height: 22px;
  }
`;

const titleCardStyles = ({ theme }) => css`
  display: flex;
  align-items: center;
  width: 100%;
  font-weight: 500;
  padding: 20px 30px;
  background: ${theme.colors.lightblue};
  border-radius: 4px;

  p {
    color: ${theme.colors.background};
    font-size: ${theme.typography.xs};
  }

  svg {
    margin-right: 22px;
  }
`;

const cardContentStyles = () => css`
  padding: 15px 24px 24px 24px;
`;

const fullWidthStyles = () => css`
  width: 100%;
`;

const formStyles = ({ theme }) => css`
  background: ${theme.colors.gray5};
  border: 1px solid ${theme.colors.gray6};
  border-radius: 4px;
  padding: 38px 32px 50px;
  opacity: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;

  input {
    width: 100%;
    background: ${theme.colors.gray7};
    font-family: ${theme.font.montserrat};
    border: 1px solid ${theme.colors.gray6};
    border-radius: 4px;
    padding: 14px;
    font-weight: 500;

    color: ${theme.colors.white};
    font-size: ${theme.typography.sm};

    &::placeholder {
      color: ${theme.colors.white};
    }
  }
`;

const labelStyles = ({ theme }) => css`
  color: ${theme.colors.lightblue};
  font-size: ${theme.typography.xs};
  letter-spacing: 2.22px;
  line-height: 31px;
  font-weight: 500;
`;

const sectionFormStyles = () => css`
  position: relative;
  margin-bottom: 106px;
`;

const selectStyles = ({ theme }) => css`
  width: 100%;
  background: ${theme.colors.gray7};
  font-family: ${theme.font.montserrat};
  border: 1px solid ${theme.colors.gray6};
  border-radius: 4px;
  padding: 14px;
  font-weight: 500;
  background-image: url(${chevronDown});
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: center right 1.9rem;
  -webkit-appearance: none;

  color: ${theme.colors.white};
  font-size: ${theme.typography.sm};

  &:not(:checked) {
    color: ${theme.colors.white};
    font-weight: 500;
  }

  option {
    color: ${theme.colors.white};
    font-size: ${theme.typography.sm};
  }
`;

const textAreaStyles = ({ theme }) => css`
  width: 100%;
  background: ${theme.colors.gray7};
  font-family: ${theme.font.montserrat};
  border: 1px solid ${theme.colors.gray6};
  border-radius: 4px;
  padding: 14px;
  font-weight: 500;

  color: ${theme.colors.white};
  font-size: ${theme.typography.sm};

  &::placeholder {
    color: ${theme.colors.white};
  }
`;

const formButton = ({ theme }) => css`
  background: ${theme.colors.blue};
  color: ${theme.colors.white};
  border-radius: 4px;
  padding: 12px 90px;
  font-size: ${theme.typography.md};
  font-weight: 500;
  border: 0;

  @media (max-width: ${theme.breaks.medium}) {
    width: 100%;
  }
`;

const assetRightFormStyles = ({ theme }) => css`
  position: absolute;
  right: 0;
  top: 0;
  height: 600px;

  @media (max-width: ${theme.breaks.huge}) {
    right: 0;
  }

  @media (max-width: ${theme.breaks.small}) {
    display: none;
  }
`;

const alignImagesStyles = ({ theme }) => css`
  display: flex;

  @media (max-width: ${theme.breaks.small}) {
    flex-direction: column;
    img {
      height: auto;
      margin-bottom: 20px;
    }
  }
`;

const faqSectionStyles = () => css`
  margin-bottom: 13.5rem;
`;

const imageBootcampLogoStyles = () => css`
  width: 105px;
  margin: 30px 0;
`;

const infosBootcampWrapperStyles = ({ theme }) => css`
  display: flex;

  svg {
    margin-right: 8px;
  }

  p {
    font-size: ${theme.typography.sm};
    color: ${theme.colors.white};
    line-height: 24px;
  }

  margin-bottom: 23px;
`;

const facilitatorSectionStyles = () => css``;

const facilitatorTitleStyles = ({ theme }) => css`
  font-size: ${theme.typography.xl2};
  color: ${theme.colors.white};
  font-weight: bold;
  margin-bottom: 25px;
`;

const facilitatorCardStyles = ({ theme }) => css`
  display: flex;

  img {
    border-radius: 50%;
    width: 68px;
    height: 68px;
    object-fit: cover;
    margin-right: 34px;
  }
`;

const facilitatorInfoStyles = () => css``;

const facilitatorNameStyles = ({ theme }) => css`
  font-size: 24px;
  color: ${theme.colors.blue};

  svg {
    margin-left: 16px;
    transition: all 0.3s cubic-bezier(0.25, 0.39, 0.39, 2.01);
  }

  svg:hover {
    transform: scale(1.2);
    transition: all 0.3s cubic-bezier(0.25, 0.39, 0.39, 2.01);
  }
`;

const alignCenterStyles = () => css`
  display: flex;
  align-items: center;
`;

const generalVisionStyles = ({ theme }) => css`
  p {
    font-size: ${theme.typography.md};
    color: ${theme.colors.white};
    font-weight: 500;
    line-height: 24px;
    opacity: 60%;
  }

  margin-bottom: 15rem;
`;

const boxgeneralVisionStyles = ({ theme }) => css`
  background: ${theme.colors.gray8};
  border-radius: 8px;
  padding: 20px 30px;
  border: 1px solid #2e3436;

  height: 7.5rem;
  @media (max-width: 560px) {
    height: auto;
  }
`;

const imageDefaultBannerStyles = ({ theme }) => css`
  width: 100%;
  object-fit: contain;

  @media (min-width: 959px) {
    margin-left: 180px;
  }

  @media (max-width: ${theme.breaks.medium}) {
    min-width: auto;
  }
`;

export const Wrapper = styled('div')(wrapperStyles);
export const WrapperBanner = styled('div')(wrapperBannerStyles);
export const ContentBanner = styled('div')(contentBannerStyles);
export const MarginTextBanner = styled('div')(marginTextBannerStyles);
export const ImageBanner = styled('img')(imageBannerStyles);

export const Title = styled('h1')(titleStyles);
export const ItemTitleWhy = styled('h1')(titleWhyStyles);
export const LearnSection = styled('div')(learnSectionStyles);
export const LearnCard = styled('div')(learnCardStyles);
export const WrapperWhyTitle = styled('div')(wrapperWhyTitleStyles);

export const TitleBanner = styled('h1')(titleBannerStyles);
export const GridBanner = styled(Grid)(gridBanner);

export const Card = styled(Grid)(cardStyles);
export const TitleCard = styled(Grid)(titleCardStyles);
export const CardContent = styled(Grid)(cardContentStyles);
export const CardButton = styled(U.Button)(fullWidthStyles);

export const Form = styled('div')(formStyles);
export const Label = styled('p')(labelStyles);
export const FormSection = styled('section')(sectionFormStyles);
export const Select = styled('select')(selectStyles);
export const TextArea = styled('textarea')(textAreaStyles);
export const ButtonForm = styled(motion.button)(formButton);
export const AssetRightForm = styled('img')(assetRightFormStyles);
export const AlignImages = styled('div')(alignImagesStyles);

export const FAQSection = styled('section')(faqSectionStyles);
export const TextTopBanner = styled('h1')(textTopBannerStyles);

export const InfosBootcampWrapper = styled('div')(infosBootcampWrapperStyles);
export const ImageBootcampLogo = styled('img')(imageBootcampLogoStyles);

export const FacilitatorSection = styled('div')(facilitatorSectionStyles);
export const FacilitatorTitle = styled('h1')(facilitatorTitleStyles);
export const FacilitatorCard = styled('div')(facilitatorCardStyles);
export const FacilitatorInfo = styled('div')(facilitatorInfoStyles);
export const FacilitatorName = styled('h1')(
  facilitatorNameStyles,
  alignCenterStyles
);

export const GeneralVisionSection = styled('div')(generalVisionStyles);
export const BoxGeneralVision = styled('div')(boxgeneralVisionStyles);
export const ImageDefaultBanner = styled('img')(imageDefaultBannerStyles);

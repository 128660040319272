import styled from '@emotion/styled';
import { css } from '@emotion/react';

const mainStyles = ({ theme }) => css`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: ${theme.breaks.large}) {
    margin-top: 5rem;
  }
`;

const titleStyles = ({ theme }) => css`
  font-size: 50px;
  color: ${theme.colors.lightblue};
  letter-spacing: 2.22px;
  font-weight: bold;

  @media (max-width: ${theme.breaks.medium}) {
    font-size: 30px;
  }
`;

export const Main = styled('div')(mainStyles);
export const Title = styled('h1')(titleStyles);

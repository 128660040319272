import React, { useEffect } from 'react';

import { motion } from 'framer-motion';
import { animateScroll as scroll } from 'react-scroll';
import { Layout } from '~/components';
import { backVariants } from '~/styles/animations';

import * as S from './styled';
import * as U from '~/styles/utilities';

const NotFound = () => {
  useEffect(() => {
    scroll.scrollToTop();
  }, []);
  return (
    <>
      <Layout noBanner>
        <U.ContainerLimit>
          <S.Main>
            <motion.div initial="exit" animate="enter" exit="exit">
              <motion.div variants={backVariants}>
                <S.Title>404</S.Title>
              </motion.div>
            </motion.div>
          </S.Main>
        </U.ContainerLimit>
      </Layout>
    </>
  );
};

export default NotFound;

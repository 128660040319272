import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { motion } from 'framer-motion';

import Grid from '@material-ui/core/Grid';

import * as U from '~/styles/utilities';

const wrapperStyles = () => css`
  position: relative;
`;

const containerStyles = () => css`
  padding-bottom: 40px;
`;

const logoContainerStyles = () => css`
  @media (max-width: 1030px) {
    padding: 0 40px 40px 40px;

    img {
      width: 100%;
    }
  }
`;

const alignIconsStyles = ({ theme }) => css`
  display: flex;
  width: 60px;
  justify-content: space-between;

  margin-top: 40px;
  svg {
    @media (min-width: ${theme.breaks.medium1}) {
      cursor: pointer;
    }
  }
`;

const footerStyles = () => css`
  position: relative;
  padding: 70px 0 10px 0;
`;

const backgroundStyles = ({ theme }) => css`
  top: 0px;
  left: 0px;
  position: absolute;
  width: 47%;
  height: 100%;

  background: ${theme.colors.gray3};
  border-radius: 4px;

  @media (max-width: ${theme.breaks.medium}) {
    width: 100%;
  }
`;

const asideStyles = ({ theme }) => css`
  height: 257px;
  padding: 50px;

  background: ${theme.colors.gray1};
  border-radius: 4px;
`;

const iconTopWrapper = ({ theme }) => css`
  padding: 10px;
  border-radius: 4px;
  position: absolute;
  right: 20px;
  display: flex;
  bottom: 20px;
  z-index: 1;
  border: 0;

  background: ${theme.colors.gray3};
  @media (max-width: ${theme.breaks.medium}) {
    background: ${theme.colors.background};
  }
`;

export const Container = styled('div')(containerStyles);
export const LogoContainer = styled('div')(logoContainerStyles);
export const AlignIcons = styled('div')(alignIconsStyles);
export const Footer = styled('footer')(footerStyles);
export const Background = styled('div')(backgroundStyles);
export const Aside = styled('aside')(asideStyles);
export const GridAsideRight = styled(Grid)(wrapperStyles);
export const IconTopWrapper = styled(motion.button)(iconTopWrapper);

import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { Formik } from 'formik';

import * as S from './styled';
import * as U from '~/styles/utilities';
import * as F from '~/styles/form';

import { phoneWithDDDMask } from '~/utils/remask';
import { Toast, Loading } from '~/components';

import validations from './validations';
import api from '~/services/api';

const ConsultancyForm = () => {
  const [loading, setLoading] = useState(false);

  // form

  const initialValues = {
    name: '',
    email: '',
    company: '',
    city: '',
    phone: '',
  };

  const handleSubmitForm = async (data, { resetForm }) => {
    try {
      setLoading(true);
      await api.post('/contact/', data);
      Toast.success('Enviado com sucesso!');
      setLoading(false);
      resetForm(initialValues);
    } catch (err) {
      setLoading(false);
      Toast.error('Algo deu errado, tente novamente mais tarde');
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmitForm}
      validationSchema={validations()}
    >
      {({
        handleSubmit,
        handleBlur,
        handleChange,
        values,
        errors,
        touched,
        setFieldValue,
      }) => (
        <>
          <form onSubmit={handleSubmit}>
            <F.FormContent padding="38px 0px 50px">
              <U.TitleShapes>TEM INTERESSE?</U.TitleShapes>
              <S.TitleForm>Fale conosco!</S.TitleForm>
              <U.Gap size={20} />
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                  <F.Label>Nome completo</F.Label>
                  <F.Input
                    type="text"
                    placeholder="Digite seu nome"
                    name="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                  />
                  {errors.name && touched.name ? (
                    <F.Hint error={!!errors.name && touched.name}>
                      {errors.name}
                    </F.Hint>
                  ) : null}
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <F.Label>E-mail</F.Label>
                  <F.Input
                    type="email"
                    placeholder="Digite seu e-mail"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                  {errors.email && touched.email ? (
                    <F.Hint error={!!errors.email && touched.email}>
                      {errors.email}
                    </F.Hint>
                  ) : null}
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <F.Label>Empresa</F.Label>
                  <F.Input
                    type="text"
                    placeholder="Digite o nome da empresa"
                    name="company"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.company}
                  />
                  {errors.company && touched.company ? (
                    <F.Hint error={!!errors.company && touched.company}>
                      {errors.company}
                    </F.Hint>
                  ) : null}
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <F.Label>Cidade</F.Label>
                  <F.Input
                    type="text"
                    placeholder="Digite sua cidade"
                    name="city"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.city}
                  />
                  {errors.city && touched.city ? (
                    <F.Hint error={!!errors.city && touched.city}>
                      {errors.city}
                    </F.Hint>
                  ) : null}
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <F.Label>Telefone</F.Label>
                  <F.Input
                    type="text"
                    placeholder="Digite o telefone"
                    name="phone"
                    onChange={(e) =>
                      setFieldValue('phone', phoneWithDDDMask(e.target.value))
                    }
                    onBlur={handleBlur}
                    value={values.phone}
                  />
                  {errors.phone && touched.phone ? (
                    <F.Hint error={!!errors.phone && touched.phone}>
                      {errors.phone}
                    </F.Hint>
                  ) : null}
                </Grid>
                <Grid container item xs={12} sm={6} md={4}>
                  <F.Label className="hidden">blank space</F.Label>
                  <F.ButtonForm
                    type="submit"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    full
                    className="flex-center"
                  >
                    {loading ? <Loading /> : 'ENVIAR'}
                  </F.ButtonForm>
                  {errors && <F.Hint className="hidden">blank</F.Hint>}
                </Grid>
              </Grid>
            </F.FormContent>
          </form>
        </>
      )}
    </Formik>
  );
};

export default ConsultancyForm;

import React from 'react';

import { motion } from 'framer-motion';

import PropTypes from 'prop-types';

import * as S from './styled';

const Path = (props) => (
  <motion.path
    fill="transparent"
    strokeLinecap="round"
    strokeWidth="3"
    {...props}
  />
);

const transition = { duration: 0.33 };

const Header = ({ open, setOpen }) => (
  <S.Header open={open}>
    <S.AlignmentHeader>
      <div>
        <S.IconMenuMobile open={open} onClick={() => setOpen(!open)}>
          <svg width="23" height="23" viewBox="0 0 23 23">
            <Path
              animate={open ? 'open' : 'closed'}
              initial={false}
              variants={{
                closed: { d: 'M 2 2.5 L 20 2.5', stroke: '#fff' },
                open: { d: 'M 3 16.5 L 17 2.5', stroke: '#fff' },
              }}
              transition={transition}
            />
            <Path
              d="M 2 9.423 L 20 9.423"
              stroke="#fff"
              animate={open ? 'open' : 'closed'}
              initial={false}
              variants={{
                closed: { opacity: 1 },
                open: { opacity: 0 },
              }}
              transition={transition}
            />
            <Path
              animate={open ? 'open' : 'closed'}
              initial={false}
              variants={{
                closed: {
                  d: 'M 2 16.346 L 20 16.346',
                  stroke: '#fff',
                },
                open: { d: 'M 3 2.5 L 17 16.346', stroke: '#fff' },
              }}
              transition={transition}
            />
          </svg>
        </S.IconMenuMobile>
      </div>
    </S.AlignmentHeader>
  </S.Header>
);

export default Header;

Header.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

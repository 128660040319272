import styled from '@emotion/styled';
import { css } from '@emotion/react';

const wrapperStyles = () => css`
  position: relative;
`;

const containerTitleStyles = ({ theme }) => css`
  position: relative;
  margin-top: 75px;
  z-index: 1;
  padding-top: 12px;

  &:before {
    content: '';
    height: 230px;
    width: 2px;
    background: #fff;
    position: absolute;

    @media (max-width: ${theme.breaks.medium}) {
      margin-left: -15px;
    }
  }
`;

const titleStyles = () => css`
  position: relative;
  display: flex;
  align-items: center;
`;

const titleWrapperStyles = ({ theme }) => css`
  display: flex;
  width: 350px;
  justify-content: space-between;
  align-items: center;
  margin: 24px 0 56px 0;
  position: relative;

  @media (max-width: ${theme.breaks.medium}) {
    width: 288px;
  }

  span {
    background: #fff;
    height: 2px;
    transition: width 0.3s ease-out;

    @media (max-width: ${theme.breaks.medium}) {
      margin-left: -13px;
    }
  }

  svg {
    color: ${theme.colors.lightblue};
    margin-right: 20px;
    transition: 0.3s ease-out;
  }

  p {
    color: ${theme.colors.blue};
    font-size: ${theme.typography.sm};
    font-weight: 500;
  }

  span {
    width: 60px;
    margin-right: 20px;

    @media (max-width: ${theme.breaks.medium}) {
      width: 40px;
    }
  }

  &:hover {
    svg {
      color: ${theme.colors.white};
      margin: 0;
      transition: 0.3s ease-out;
    }

    p {
      color: ${theme.colors.white};
      font-size: ${theme.typography.sm};
      font-weight: 500;
      transition: 0.3s ease-out;
    }

    span {
      width: 94px;
      margin-right: 20px;
    }
  }
`;

const imageBackgroundStyles = ({ theme }) => css`
  width: 100%;
  height: 251px;
  object-fit: cover;

  @media (min-width: ${theme.breaks.large}) {
    padding-left: 40px;
  }
`;

const areaContentStyles = ({ active }) => css`
  opacity: ${active ? 1 : 0};
  transition: opacity 0.5s ease-out;
  top: 0;
  position: absolute;
`;

export const Wrapper = styled('div')(wrapperStyles);
export const ContainerTitle = styled('div')(containerTitleStyles);
export const Title = styled('div')(titleStyles);
export const TitleWrapper = styled('div')(titleWrapperStyles);
export const ImageBackground = styled('img')(imageBackgroundStyles);
export const AreaContentWrapper = styled('div')(areaContentStyles);

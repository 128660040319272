import React, {
  createContext,
  useState,
  useContext,
  useCallback,
  useEffect,
} from 'react';
import api from '~/services/api';

const FaqContext = createContext();

const FaqProvider = ({ children }) => {
  const [otcFaq, setOtcFaq] = useState([]);

  const loadFaq = useCallback(async () => {
    try {
      const responseFaq = await api.get('faq/');
      setOtcFaq(responseFaq.data);
    } catch {
      setOtcFaq([]);
    }
  }, []);

  useEffect(() => {
    loadFaq();
  }, [loadFaq]);

  return (
    <FaqContext.Provider value={{ loadFaq, otcFaq }}>
      {children}
    </FaqContext.Provider>
  );
};

function useFaqContext() {
  const context = useContext(FaqContext);

  if (!context) {
    throw new Error('useFaqContext must be used within an FaqProvider');
  }
  return context;
}
export { FaqProvider, useFaqContext };

const theme = {
  colors: {
    background: '#252C2D',
    background2: '#F2F2F2',
    white: '#ffffff',
    black: '#000000',
    black1: '#1E2121',
    black2: '#242828',
    lightblue: '#19F7FF',
    lightblue2: '#1ADAE1',
    lightblue3: '#3DDFFF',
    blue: '#23A6C0',
    blue1: 'rgb(23,33,68)',
    gray: '#707070',
    gray1: '#464646',
    gray2: '#E2E2E2',
    gray3: '#4b5253',
    gray4: '#747474',
    gray5: '#3A4547',
    gray6: '#696868',
    gray7: '#898F91',
    gray8: '#2D3335',
    orange: '#C07723',
    yellow: '#C0B623',
    yellow1: '#F3CA31',
    green: '#4CC023',
    green2: '#97ffc8',
    red: '#ff6c6c',
  },

  spacing: {
    none: '0px',
    xs: '4px',
    s: '8px',
    s1: '13px',
    m: '16px',
    l: '24px',
    xl: '32px',
  },

  breaks: {
    huge: '1440px',
    large: '1170px',
    medium: '768px',
    medium1: '960px',
    small: '450px',
    small1: '350px',
  },

  font: {
    montserrat: "'Montserrat', sans-serif",
  },

  typography: {
    xs: '12px',
    sm: '14px',
    md: '16px',
    md1: '18px',
    lg: '20px',
    xl: '22px',
    xl2: '24px',
    xl3: '30px',
    xl4: '40px',
    xl5: '64px',
  },
};

export default theme;

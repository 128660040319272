import React from 'react';
import { StatesProvider } from './modules/StatesContext';
import { CoursesProvider } from './modules/CoursesContext';
import { BootcampsProvider } from './modules/BootcampsContext';
import { FaqProvider } from './modules/FaqContext';
import { CheckoutProvider } from './modules/CheckoutContext';
import { UserProvider } from './modules/UserContext';

const AppProvider = ({ children }) => (
  <StatesProvider>
    <UserProvider>
      <CheckoutProvider>
        <CoursesProvider>
          <BootcampsProvider>
            <FaqProvider>{children}</FaqProvider>
          </BootcampsProvider>
        </CoursesProvider>
      </CheckoutProvider>
    </UserProvider>
  </StatesProvider>
);

export default AppProvider;

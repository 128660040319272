import React, {
  createContext,
  useState,
  useContext,
  useCallback,
  useEffect,
} from 'react';
import api from '~/services/api';

const BootcampsContext = createContext();

const BootcampsProvider = ({ children }) => {
  const [bootcamps, setBootcamps] = useState([]);

  const loadBootcamps = useCallback(async () => {
    try {
      const responseCourses = await api.get('bootcamps/');
      setBootcamps(responseCourses.data);
    } catch {
      setBootcamps([]);
    }
  }, []);

  useEffect(() => {
    loadBootcamps();
  }, [loadBootcamps]);

  return (
    <BootcampsContext.Provider value={{ loadBootcamps, bootcamps }}>
      {children}
    </BootcampsContext.Provider>
  );
};

function useBootcampsContext() {
  const context = useContext(BootcampsContext);

  if (!context) {
    throw new Error(
      'useBootcampsContext must be used within an BootcampsProvider'
    );
  }
  return context;
}
export { BootcampsProvider, useBootcampsContext };

import styled from '@emotion/styled';
import { css } from '@emotion/react';

const wrapperStyles = () => css`
  position: relative;
`;

const teacherImgStyles = ({ theme, active }) => css`
  border-radius: 4px;
  border: 1px solid ${theme.colors.gray};
  height: 510px;
  width: 345px;
  object-fit: cover;

  @media (max-width: ${theme.breaks.large}) {
    width: 100%;
    height: 300px;
  }

  @media (max-width: 600px) {
    width: 100%;
    height: auto;
  }
  transition: 0.5s ease-out;

  ${active
    ? css`
        filter: opacity(1);
      `
    : `filter: opacity(0.8);
  `}
`;

const teacherInfoStyles = ({ theme, active }) => css`
  background: #46464687 0% 0% no-repeat padding-box;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  opacity: 1;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  padding: 25px 40px;
  margin-top: -106px;
  position: absolute;
  right: 0;
  bottom: -30px;
  width: 267px;

  @media (max-width: ${theme.breaks.large}) {
    bottom: -13px;
  }

  @media (max-width: ${theme.breaks.medium}) {
    width: 255px;
  }

  &:before {
    content: '';
    position: absolute;
    background: inherit;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #0debe3 0% 0% no-repeat padding-box;
    transition: 0.5s ease-out;

    ${active
      ? css`
          filter: blur(20px);
          opacity: 0.5;
        `
      : css`
          filter: blur(20px);
          opacity: 0.2;
        `}
  }
`;

const teacherNameStyles = ({ theme }) => css`
  font-size: ${theme.typography.xl2};
  color: ${theme.colors.white};
  font-weight: bold;
  margin-bottom: 5px;

  @media (max-width: ${theme.breaks.medium}) {
    font-size: ${theme.typography.md};
  }
`;

const teacherDescStyles = ({ theme }) => css`
  font-size: ${theme.typography.md};
  color: ${theme.colors.white};
  font-weight: 500;
  position: relative;

  svg {
    position: absolute;
    right: -20px;
    transition: all 0.3s cubic-bezier(0.25, 0.39, 0.39, 2.01);
  }

  svg:hover {
    transform: scale(1.2);
    transition: all 0.3s cubic-bezier(0.25, 0.39, 0.39, 2.01);
  }
`;

export const TeacherDesc = styled('div')(teacherDescStyles);
export const TeacherName = styled('h1')(teacherNameStyles);
export const TeacherWrapper = styled('div')(wrapperStyles);
export const TeacherImg = styled('img')(teacherImgStyles);
export const TeacherInfo = styled('div')(teacherInfoStyles);

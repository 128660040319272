import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { motion } from 'framer-motion';

const wrapperStyles = () => css`
  position: relative;
`;

const headerStyles = ({ theme }) => css`
  margin-bottom: 8px;
  border: 1px solid #575b5b;
  border-radius: 4px;
  opacity: 1;

  @media (min-width: ${theme.breaks.medium1}) {
    cursor: pointer;
  }
`;

const titleAskStyles = ({ theme }) => css`
  font-size: ${theme.typography.md};
  color: ${theme.colors.white};
  font-weight: 500;
`;

const askStyles = ({ theme }) => css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px;
  svg {
    color: ${theme.colors.white};
  }
`;

const answerStyles = () => css`
  padding: 0 24px 24px;
`;

export const Wrapper = styled('div')(wrapperStyles);
export const Header = styled(motion.div)(headerStyles);
export const TitleAsk = styled('h1')(titleAskStyles);
export const Ask = styled('div')(askStyles);
export const Answer = styled('div')(answerStyles);

import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { motion } from 'framer-motion';
import { Link } from 'react-scroll';
import { pulsate } from '~/styles/animations';

import chart from '~/assets/img/chart.png';

const titleStyles = ({ theme }) => css`
  color: ${theme.colors.yellow};
`;

const wrapperBannerStyles = ({ theme }) => css`
  min-height: 100vh;

  @media (max-width: ${theme.breaks.small}) {
    min-height: 90vh;
  }

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 100px;
`;

const contentBannerStyles = ({ theme }) => css`
  display: flex;
  align-items: center;

  @media (max-width: ${theme.breaks.large}) {
    flex-direction: column-reverse;
    min-height: auto;
    justify-content: flex-end;
    text-align: center;
  }
`;

const marginTextBannerStyles = ({ theme }) => css`
  @media (min-width: ${theme.breaks.large}) {
    margin-top: 170px;
  }
`;

const imageBannerStyles = ({ theme }) => css`
  width: 95%;
  object-fit: contain;

  @media (max-width: ${theme.breaks.large}) {
    min-width: auto;
    margin-top: -88px;
  }
`;

const moreKnowledgeStyles = ({ theme }) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px;
  @media (min-width: ${theme.breaks.medium1}) {
    cursor: pointer;
  }

  span {
    position: relative;
    background: #234950;
    display: flex;
    margin-left: 15px;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    border-radius: 50%;
  }

  span:before {
    content: '';
    border: 13px solid #244950;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    position: absolute;
    left: -13px;
    bottom: -13px;
    animation: ${pulsate} 1.6s ease-out;
    animation-iteration-count: infinite;
    opacity: 0;
    z-index: 99;
  }

  @media (max-width: ${theme.breaks.large}) {
    span {
      height: 30px;
      width: 30px;
    }

    span:before {
      height: 30px;
      width: 30px;
    }
  }

  color: ${theme.colors.blue};
  font-size: ${theme.typography.md};
  font-weight: 500;
`;

const introSectionStyles = () => css`
  display: flex;
  margin-bottom: 90px;
`;

const chartSectionStyles = () => css`
  background-image: url(${chart});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-size: contain;
  height: 600px;
  margin-bottom: 90px;
`;

const otcSectionStyles = () => css`
  display: flex;
  position: relative;
  margin-bottom: 110px;
  align-items: center;
`;

const otcAssetLeftStyles = () => css`
  position: absolute;
  left: -120px;
  bottom: 0;

  @media (max-width: ${({ theme }) => theme.breaks.huge}) {
    left: 0;
    bottom: -50px;
  }
`;

const otcAssetRightStyles = ({ theme }) => css`
  position: absolute;
  top: 0;
  right: 0;

  @media (max-width: ${theme.breaks.huge}) {
    top: -70px;
  }
`;

const otcTitleStyles = ({ theme }) => css`
  color: ${theme.colors.blue};
  opacity: 22%;
  font-size: 159px;
  letter-spacing: -6.84px;
  font-weight: bold;

  @media (max-width: ${theme.breaks.large}) {
    font-size: 100px;
  }
`;

const otcTitleBlueStyles = ({ theme }) => css`
  font-size: ${theme.typography.xs};
  color: ${theme.colors.lightblue};
  letter-spacing: 2.22px;
  font-weight: 500;
`;

const otcAlignStyles = ({ theme }) => css`
  margin-left: -50px;
  z-index: 1;

  @media (max-width: ${theme.breaks.large}) {
    margin-left: -30px;
  }
`;

const coursesSectionStyles = () => css`
  margin-bottom: 110px;
`;

const trainingSectionStyles = ({ theme }) => css`
  position: relative;
  margin-bottom: 240px;

  @media (max-width: ${theme.breaks.medium1}) {
    margin-bottom: 100px;
  }
`;

const trainingAssetRightStyles = ({ theme }) => css`
  position: absolute;
  right: 0;
  top: 0;
  height: 379px;

  @media (max-width: ${theme.breaks.huge}) {
    right: 0;
  }

  @media (max-width: ${theme.breaks.small}) {
    display: none;
  }
`;

const consultanciesSectionStyles = () => css`
  margin-bottom: 120px;

  svg {
    margin-bottom: 20px;
  }
`;

const consultancyStyles = ({ theme }) => css`
  background-color: ${theme.colors.gray1};
  border: 1px solid ${theme.colors.gray2};
  border-radius: 4px;
  height: 170px;
`;

const consultancyImage = ({ theme }) => css`
  width: 40%;
  position: absolute;
  bottom: -90px;
  height: 416px;
  object-fit: cover;

  @media (max-width: ${theme.breaks.medium1}) {
    width: 100%;
    position: relative;
    bottom: 0;
    height: auto;
  }
`;

export const Title = styled('h1')(titleStyles);
export const WrapperBanner = styled('div')(wrapperBannerStyles);
export const ContentBanner = styled('div')(contentBannerStyles);
export const MarginTextBanner = styled('div')(marginTextBannerStyles);
export const ImageBanner = styled('img')(imageBannerStyles);
export const MoreKnowledge = styled(Link)(moreKnowledgeStyles);
export const IntroSection = styled('section')(introSectionStyles);
export const ChartSection = styled('section')(chartSectionStyles);
export const OTCSection = styled('section')(otcSectionStyles);
export const OTCAssetLeft = styled('img')(otcAssetLeftStyles);
export const OTCAssetRight = styled('img')(otcAssetRightStyles);
export const OTCTitle = styled('h1')(otcTitleStyles);
export const OTCTitleBlue = styled('h1')(otcTitleBlueStyles);
export const OTCAlign = styled('section')(otcAlignStyles);
export const CoursesSection = styled('section')(coursesSectionStyles);
export const TrainingSection = styled('section')(trainingSectionStyles);
export const TrainingAssetRight = styled('img')(trainingAssetRightStyles);
export const ConsultanciesSection = styled('section')(
  consultanciesSectionStyles
);
export const Consultancy = styled('div')(consultancyStyles);
export const ConsultancyImage = styled('img')(consultancyImage);

import styled from '@emotion/styled';
import { css } from '@emotion/react';

const arrowImgStyles = ({ theme }) => css`
  position: absolute;

  bottom: -55px;
  width: 55px;

  transform: rotate(90deg);

  @media (max-width: ${theme.breaks.medium1}) {
    display: none;
  }
`;

export const ArrowImg = styled('img')(arrowImgStyles);

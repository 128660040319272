import React from 'react';
import ReactDOM from 'react-dom';

import { Global, ThemeProvider } from '@emotion/react';
import AppProvider from './hooks/context';

import globalStyles from './styles/global';
import theme from './styles/theme';

import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <AppProvider>
        <Global styles={globalStyles} />
        <App />
      </AppProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

import * as yup from 'yup';

export default function validations() {
  return yup.object().shape({
    username: yup
      .string()
      .email('Insira um e-mail válido')
      .required('O e-mail é obrigatório'),

    password: yup.string().required('A senha é obrigatória'),
  });
}

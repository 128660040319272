import styled from '@emotion/styled';
import { css } from '@emotion/react';

const headerStyles = ({ theme, open }) => css`
  display: flex;
  position: relative;

  @media (max-width: ${theme.breaks.large}) {
    padding: ${open ? '2.73rem 0' : '2rem 0'};
    top: 0;
    left: 0;
    width: 100%;
  }
`;

const iconMenuMobileStyles = ({ theme, open }) => css`
  @media (min-width: ${theme.breaks.medium1}) {
    cursor: pointer;
  }
  display: none;

  @media (max-width: ${theme.breaks.large}) {
    display: flex;
    align-items: center;
  }
  svg {
    z-index: 999;
  }

  ${open &&
  css`
    position: fixed;
    right: 32px;
    top: 32px;
    z-index: 9999;
  `}
`;

const alignmentHeaderStyles = () => css`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const Header = styled('div')(headerStyles);
export const IconMenuMobile = styled('div')(iconMenuMobileStyles);
export const AlignmentHeader = styled('div')(alignmentHeaderStyles);

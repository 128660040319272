import styled from '@emotion/styled';
import { css } from '@emotion/react';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';

import * as U from '~/styles/utilities';

const courseImgStyles = () => css`
  width: 120px;
  margin: 14px 0;
`;

const titleBigBannerStyles = ({ theme, type }) => css`
  color: ${(type === 'circle' && theme.colors.lightblue) ||
  (type === 'square' && theme.colors.orange) ||
  (type === 'triangle' && theme.colors.yellow) ||
  (type === 'diamond' && theme.colors.green) ||
  theme.colors.lightblue};
  font-size: 26px;
  margin-bottom: ${theme.spacing.xl};
  font-weight: bold;
  margin-bottom: 25px;
  margin-top: 32px;
  text-transform: uppercase;
  line-height: 43px;
  letter-spacing: 4.81px;
`;

const wrapperBannerStyles = ({ theme, noMinHeight }) => css`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: ${theme.breaks.medium}) {
    min-height: ${!noMinHeight && '860px'};
  }

  @media (max-width: ${theme.breaks.medium}) {
    min-height: 100vh;
  }
`;

const contentBannerStyles = ({ theme }) => css`
  display: flex;
  align-items: center;

  @media (max-width: ${theme.breaks.medium}) {
    flex-direction: column-reverse;
    min-height: auto;
    justify-content: flex-end;
    text-align: center;
  }
`;

export const MarginTextBanner = styled.div``;

const imageBannerStyles = ({ theme }) => css`
  width: 100%;
  object-fit: cover;
  height: 376px;
  border-radius: 10px;
  transform: rotateY(-10deg);

  ${'' /* @media (min-width: 959px) {
    margin-left: 180px;
  } */}

  @media (max-width: ${theme.breaks.medium1}) {
    margin-bottom: 50px;
    height: 500px;
  }

  @media (max-width: ${theme.breaks.medium}) {
    height: 376px;
  }

  @media (max-width: ${theme.breaks.small}) {
    height: 250px;
  }
`;

const otcSectionStyles = () => css`
  display: flex;
  position: relative;
  margin-bottom: 110px;
  align-items: center;
`;

const gridBannerStyles = () => css`
  @media (max-width: 959px) {
    flex-direction: column-reverse;
  }
`;

const imageBackgroundStyles = ({ theme }) => css`
  position: absolute;
  top: -15px;
  left: -105px;
  opacity: 1;
  transition: opacity 0.5s ease-out;
  width: 262px;
  object-fit: contain;

  @media (max-width: ${theme.breaks.huge}) {
    display: none;
    position: relative;
  }
`;

const wrapperStyles = () =>
  css`
    position: relative;
  `;

const imageDefaultBannerStyles = ({ theme }) => css`
  width: 100%;
  object-fit: contain;

  @media (min-width: 959px) {
    margin-left: 180px;
  }

  @media (max-width: ${theme.breaks.medium}) {
    min-width: auto;
  }
`;

const titleStyles = ({ theme, blue, size }) => css`
  color: ${blue ? theme.colors.lightblue : theme.colors.white};
  line-height: 40px;
`;

const linkStyles = ({ theme }) => css`
  color: ${theme.colors.white};
  font-size: ${theme.typography.xs1};
  margin-bottom: 24px;

  &:hover {
    text-decoration: underline;
  }
`;

const bannerStyles = ({ theme, marginAdjust }) => css`
  @media (max-width: ${theme.breaks.medium1}) {
    margin: ${marginAdjust};
  }
`;

export const Title = styled('h1')(titleStyles);
export const TitleBigBanner = styled('h1')(titleBigBannerStyles);
export const WrapperBanner = styled('div')(wrapperBannerStyles);
export const ContentBanner = styled('div')(contentBannerStyles);
export const ImageBanner = styled('img')(imageBannerStyles);
export const ImageDefaultBanner = styled('img')(imageDefaultBannerStyles);

export const OTCSection = styled('section')(otcSectionStyles);

export const CourseImg = styled('img')(courseImgStyles);
export const GridBanner = styled(Grid)(gridBannerStyles);
export const ImageBackground = styled('img')(imageBackgroundStyles);
export const Wrapper = styled('div')(wrapperStyles);
export const LinkHeroBanner = styled(Link)(linkStyles);
export const Banner = styled('div')(bannerStyles);

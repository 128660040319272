import styled from '@emotion/styled';
import { css, keyframes } from '@emotion/react';

const spin = keyframes`
to {
  transform: rotate(360deg);
}
`;

const loadingStyles = () => css`
  border: 4px solid rgba(0, 0, 0, 0.2);
  border-left-color: #ffffff42;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  animation: ${spin} 1s linear infinite;
`;

export const Loading = styled('div')(loadingStyles);

import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { motion } from 'framer-motion';

const backgroundStyles = ({ theme }) => css`
  min-height: 100vh;
  display: flex;

  background-image: linear-gradient(
    to left top,
    #000948,
    #092d74,
    #1053a2,
    #0d7bd1,
    #00a6ff
  );
`;

const backgroundExcelPageStyles = ({ theme }) => css`
  min-height: 100vh;
  display: flex;

  /* background-image: linear-gradient(
    to left top,
    #182925,
    #2a4a42,
    #386e60,
    #368570,
    #35a688
  ); */

  /* background-image: linear-gradient(
    to left top,
    #35a688,
    #368570,
    #386e60,
    #2a4a42,
    #182925
  ); */
  background-color: #182925;
`;

const titleStyles = ({ theme, spanColor }) => css`
  font-size: ${theme.typography.xl2};
  color: ${theme.colors.white};
  font-weight: bold;
  line-height: 33.6px;

  span {
    color: ${spanColor || theme.colors.yellow1};
  }
`;

const titleCertificateStyles = ({ theme }) => css`
  font-size: 28px;
  color: #777777;
  font-weight: bold;
  line-height: 33.6px;

  span {
    color: ${theme.colors.yellow1};
  }

  @media (max-width: ${theme.breaks.medium}) {
    font-size: 20px;
  }
`;

const textStyles = ({ theme }) => css`
  font-size: ${theme.typography.md};
  color: ${theme.colors.white};
  font-weight: bold;
  line-height: 22.4px;

  span {
    color: ${theme.colors.yellow1};
  }
`;

const logoModalStyles = ({ theme }) => css`
  height: 50px;

  @media (max-width: ${theme.breaks.medium}) {
    display: none;
  }
`;

const titleModalStyles = ({ theme, spanColor, color }) => css`
  font-size: ${theme.typography.xl2};
  color: ${color || theme.colors.lightblue};
  line-height: 25px;

  span {
    color: ${spanColor || theme.colors.white};
  }
`;

const buttonStyles = ({ theme, disabled, buttonColorHover }) => css`
  background: ${theme.colors.white};
  font-family: 'Montserrat', sans-serif;
  border: 0;
  font-size: 13px;
  border-radius: 12px;
  padding: 15px 30px;
  text-transform: uppercase;
  color: ${theme.colors.background};
  font-weight: bold;
  width: 100%;
  transition: 0.3s;

  &:hover {
    transition: 0.3s;
    background: ${buttonColorHover || theme.colors.blue};
    color: ${theme.colors.white};
  }

  ${disabled &&
  css`
    cursor: not-allowed;
  `}

  span {
    margin-right: 10px;
  }
`;

const buttonFooterStyles = ({ theme }) => css`
  background: ${theme.colors.lightblue2};
  font-family: 'Montserrat', sans-serif;
  border: 0;
  font-size: 13px;
  border-radius: 12px;
  padding: 15px 30px;
  text-transform: uppercase;
  color: ${theme.colors.white};
  font-weight: bold;
  width: 100%;
  transition: 0.3s;

  @media (max-width: ${theme.breaks.small1}) {
    font-size: 11px;
    padding: 15px 5px;

    svg {
      height: 10px;
      width: 10px;
    }
  }

  @media (max-width: ${theme.breaks.small}) {
    font-size: 10px;
    padding: 13px 10px;
  }

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 10px;
  }

  &:hover {
    transition: 0.3s;
    background: ${theme.colors.blue};
  }
`;

const cardStyles = ({ theme, backgroundColor }) => css`
  background: ${backgroundColor || '#3a4547'};

  border-radius: 8px;
  padding: 30px;

  width: 100%;
  height: auto;
`;

const ulistStyles = ({ theme, spanColor }) => css`
  color: ${theme.colors.white};
  margin-left: 20px;
  line-height: 25px;

  li {
    font-size: ${theme.typography.md};
    color: ${theme.colors.white};
    font-weight: bold;

    span {
      color: ${spanColor || theme.colors.yellow1};
    }
  }
`;

const titleBigStyles = ({ theme }) => css`
  font-size: ${theme.typography.xl4};
  color: ${theme.colors.background};

  @media (max-width: ${theme.breaks.large}) {
    font-size: ${theme.typography.xl2};
  }

  @media (max-width: ${theme.breaks.small}) {
    font-size: ${theme.typography.xl};
  }

  span {
    color: ${theme.colors.blue};
  }
`;

const seeMoreStyles = ({ theme }) => css`
  background: ${theme.colors.white};
  padding: 50px 0;
`;

const containerLimitStyles = ({ theme }) => css`
  max-width: 67rem;
  margin: 0 auto;

  @media (max-width: ${theme.breaks.large}) {
    padding: 0 2rem;
  }

  @media (max-width: ${theme.breaks.small}) {
    padding: 0 1rem;
  }
`;

export const Background = styled('div')(backgroundStyles);
export const BackgroundExcelPage = styled('div')(backgroundExcelPageStyles);
export const Title = styled('h1')(titleStyles);
export const TitleCertificate = styled('h1')(titleCertificateStyles);
export const TitleBig = styled('h1')(titleBigStyles);
export const Text = styled('p')(textStyles);
export const LogoModal = styled('img')(logoModalStyles);
export const TitleModal = styled('h1')(titleModalStyles);
export const Button = styled(motion.button)(buttonStyles);
export const ButtonFooter = styled(motion.button)(buttonFooterStyles);
export const Card = styled('div')(cardStyles);
export const UList = styled('ul')(ulistStyles);
export const SeeMoreSection = styled('section')(seeMoreStyles);
export const ContainerLimit = styled('div')(containerLimitStyles);

import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from 'react';

import { useHistory } from 'react-router-dom';
import api from '~/services/api';
import Toast from '~/components/Alert';

import { useStateContext } from './StatesContext';

const UserContext = createContext();

const UserProvider = ({ children }) => {
  const { onCloseSign } = useStateContext();
  const history = useHistory();

  const logout = window.location.search === '?logout';

  useEffect(() => {
    if (logout) {
      localStorage.removeItem('@token');
      localStorage.removeItem('@user');
      window.location.href = '/';
    }
  }, [history, logout]);

  const [data, setData] = useState(() => {
    const token = JSON.parse(localStorage.getItem('@token'));
    const user = JSON.parse(localStorage.getItem('@user'));

    if (token && user) {
      return {
        token,
        user,
      };
    }
    return {
      token: null,
      user: null,
    };
  });

  const [isLogged, setIsLogged] = useState(() => {
    const tokenStorage = JSON.parse(localStorage.getItem('@token'));
    if (tokenStorage) {
      return true;
    }
    return false;
  });

  const [loading, setLoading] = useState(false);

  const signIn = async (dataSignIn) => {
    try {
      setLoading(true);
      const response = await api.post('/login/', dataSignIn);
      Toast.success('Login feito com sucesso!');

      const responseUser = await api.get('/user/me/', {
        headers: { Authorization: `Token ${response.data.token}` },
      });

      setData({ user: responseUser.data, token: response.data.token });

      window.location.href = `https://app.empowerdata.com.br/entrar/?token=${response.data.token}`;

      setIsLogged(true);

      localStorage.setItem('@user', JSON.stringify(responseUser.data));
      localStorage.setItem('@token', JSON.stringify(response.data.token));

      setLoading(false);

      onCloseSign();
    } catch {
      Toast.error('E-mail ou senha inválidos');
      setLoading(false);
    }
  };
  return (
    <UserContext.Provider
      value={{
        token: data.token,
        user: data.user,
        isLogged,
        setIsLogged,
        signIn,
        loading,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

function useUserContext() {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error('useUserContext must be used within an UserProvider');
  }
  return context;
}
export { UserProvider, useUserContext };

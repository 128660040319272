import styled from '@emotion/styled';
import { css } from '@emotion/react';
import ReactPlayer from 'react-player';

const wrapperStyles = () => css`
  position: relative;
  padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
  iframe {
    border-radius: 10px;
    width: 100%;
    height: 100%;
  }
`;

const playerStyles = () => css`
  position: absolute;
  top: 0;
  left: 0;
`;

export const Wrapper = styled('div')(wrapperStyles);
export const Player = styled(ReactPlayer)(playerStyles);

import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';

import Home from '~/pages/Home';
import Course from '~/pages/Course';
import Trail from '~/pages/Trail';
import OTC from '~/pages/OTC';
import InCompany from '~/pages/InCompany';
import Consultancy from '~/pages/Consultancy';
import Bootcamp from '~/pages/Bootcamp';
import PageBootcamp from '~/pages/Bootcamp/PageBootcamp';
import NotFound from '~/pages/NotFound';
import Subscription from '~/pages/Subscription';

import Checkout from '~/pages/Checkout';
import FinishPurchase from '~/pages/Checkout/FinishPurchase';
import SuccessPage from '~/pages/Checkout/SuccessPage';
import InternalError from '~/pages/InternalError';
import MissPassword from '~/pages/MissPassword';

import CertificateCareerAcelerator from '~/pages/Certificate/acelerador-carreira';
import CertificatePythonWeek from '~/pages/Certificate/semana-python';
import CertificateExcelWeek from '~/pages/Certificate/semana-excel';
import CertificateEspanhol from '~/pages/Certificate/espanhol';
import CertificateEspanholExcel from '~/pages/Certificate/espanhol-excel';

const Routes = () => (
  <Route
    render={({ location }) => (
      <AnimatePresence exitBeforeEnter>
        <Switch location={location} key={location.pathname}>
          <Route path="/certificado-python" component={CertificatePythonWeek} />
          <Route path="/certificado-excel" component={CertificateExcelWeek} />
          <Route path="/certificado" component={CertificateCareerAcelerator} />
          <Route path="/latam" component={CertificateEspanhol} />
          <Route path="/latam-excel" component={CertificateEspanholExcel} />
          <Route path="*" component={NotFound} />
        </Switch>
      </AnimatePresence>
    )}
  />
);

export default Routes;

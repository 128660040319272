import styled from '@emotion/styled';
import { css } from '@emotion/react';

const containerStyles = ({ theme }) => css`
  position: relative;
`;

const wrapperStyles = () => css`
  position: relative;
`;

const wrapperBannerStyles = ({ theme }) => css`
  min-height: 530px;

  @media (min-width: ${theme.breaks.large}) {
    margin-top: 200px;
  }

  ${
    '' /* @media (max-width: ${theme.breaks.medium}) {
    min-height: 90vh;
  } */
  }

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 100px;
`;

const contentBannerStyles = ({ theme }) => css`
  display: flex;
  align-items: flex-start;
  text-align: left;
`;

const infoSectionStyles = ({ theme }) => css`
  margin: 75px 0 250px 0;

  @media (max-width: ${theme.breaks.small}) {
    margin: 75px 0 50px 0;
  }
`;

const titleBannerStyles = ({ theme }) => css`
  font-size: 61px;
  color: ${theme.colors.lightblue};
  font-weight: bold;
  line-height: 47px;
  letter-spacing: 11.29px;
  opacity: 1;

  @media (max-width: ${theme.breaks.medium}) {
    font-size: 30px;
    line-height: 27px;
  }
`;

const imageBackgroundStyles = ({ theme }) => css`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 1;
  transition: opacity 0.5s ease-out;

  width: 235px;
  object-fit: contain;
`;

const infoCardStyles = ({ theme }) => css`
  position: relative;
  padding-top: 17px;

  @media (min-width: ${theme.breaks.large}) {
    margin: 0px 73px 0px 137px;
  }

  @media (max-width: ${theme.breaks.large}) {
    margin: 0px 0px 50px 100px;
  }
`;

const infoTitleStyles = ({ theme }) => css`
  color: ${theme.colors.white};
  line-height: 43px;
`;

const bootcampsSectionStyles = ({ theme }) => css`
  padding: 50px 0 0 0;
`;

const backgroundFooterStyles = ({ theme }) => css`
  position: relative;
  z-index: 1;

  &:before,
  &:after {
    background: ${theme.colors.black1};
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    z-index: -1;
  }

  &:before {
    top: 0;
    transform: skewY(0deg);
    transform-origin: 0% 0;
  }

  &:after {
    bottom: 0;
    transform: skewY(7deg);
    transform-origin: 100%;
  }
`;

const slideSectionStyles = () => css`
  margin: 50px 0 190px 0;
`;

const assetRightFormStyles = ({ theme }) => css`
  position: absolute;
  right: 0;
  top: 8rem;
  height: 600px;

  @media (max-width: ${theme.breaks.huge}) {
    right: 0;
  }

  @media (max-width: ${theme.breaks.small}) {
    display: none;
  }
`;

export const Container = styled('div')(containerStyles);
export const Wrapper = styled('div')(wrapperStyles);
export const WrapperBanner = styled('div')(wrapperBannerStyles);
export const ContentBanner = styled('div')(contentBannerStyles);

export const InfoSection = styled('div')(infoSectionStyles);
export const TitleBanner = styled('h1')(titleBannerStyles);

export const ImageBackground = styled('img')(imageBackgroundStyles);
export const InfoCard = styled('div')(infoCardStyles);
export const InfoTitle = styled('h1')(infoTitleStyles);
export const BootcampsSection = styled('section')(bootcampsSectionStyles);
export const BackgroundFooter = styled('div')(backgroundFooterStyles);
export const SlideSection = styled('div')(slideSectionStyles);
export const AssetRightForm = styled('img')(assetRightFormStyles);

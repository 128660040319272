import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Link } from 'react-scroll';
import * as U from '~/styles/utilities';

const wrapperStyles = () => css`
  position: relative;
`;

const wrapperBannerStyles = ({ theme }) => css`
  min-height: 530px;

  @media (min-width: ${theme.breaks.large}) {
    margin-top: 200px;
  }

  @media (max-width: ${theme.breaks.medium}) {
    min-height: 90vh;
  }

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 100px;
`;

const coursesSectionStyles = () => css`
  margin-bottom: 110px;
`;

const filterSectionStyles = () => css`
  display: flex;
  position: relative;
  margin-bottom: 110px;
  align-items: center;
`;

const bootcampsSectionStyles = () => css`
  padding: 50px 0 0 0;
`;

const slideSectionStyles = () => css`
  margin: 50px 0 190px 0;
`;

const cardCheckoutStyles = ({ theme }) => css`
  background: ${theme.colors.gray5};
  border: 1px solid ${theme.colors.gray6};
  border-radius: 4px;
  padding: 24px 54px 40px 40px;
  opacity: 1;
  min-height: 429px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: ${theme.breaks.large}) {
    padding: 24px 40px 40px;
  }
`;

const titleCheckoutStyles = ({ theme, size }) => css`
  color: ${theme.colors.white};
  font-size: ${size ? `${size}px` : '40px'};
  font-weight: bold;
  line-height: 43px;
  margin-bottom: 24px;

  @media (max-width: ${theme.breaks.medium}) {
    font-size: 30px;
  }
`;

const buttonPurchaseStyles = () => css`
  border-radius: 4px;
  padding: 12px 57px;
  margin-top: 30px;
`;

const infoStyles = ({ theme, noBorder }) => css`
  color: ${theme.colors.white};
  font-size: ${theme.typography.md};
  font-weight: 500;
  line-height: 24px;
  text-transform: uppercase;

  span {
    color: ${theme.colors.lightblue};
  }

  ${!noBorder &&
  `border-bottom: 2px solid ${theme.colors.gray6}; padding-bottom: 24px;`};
`;

const checkoutTextStyles = ({ theme }) => css`
  color: ${theme.colors.white};
  opacity: 60%;
  font-size: ${theme.typography.xs};
  font-weight: 500;
  line-height: 24px;
  margin: 27px 0 20px;
`;

const imageCourseStyles = () => css`
  height: 27px;
`;

const wrapperIconStyles = ({ theme }) => css`
  @media (min-width: ${theme.breaks.medium1}) {
    cursor: pointer;
  } ;
`;

const priceStyles = ({ theme, white }) => css`
  font-size: 16px;
  ${white && `color: ${theme.colors.white}`}
`;

const cardInfoStyles = ({ theme }) => css`
  display: flex;
  margin-top: 24px;

  font-size: ${theme.typography.m};
  p,
  svg {
    color: ${theme.colors.lightblue};
  }

  p {
    margin-left: 24px;
  }
`;

const priceTotalStyles = ({ theme }) => css`
  color: ${theme.colors.white};

  font-weight: bold;
  line-height: 43px;
`;

const continueBuyingStyles = ({ theme }) => css`
  @media (min-width: ${theme.breaks.medium1}) {
    cursor: pointer;
  } ;
`;

const wrapperEmptyCart = ({ theme }) => css`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 429px;
`;

const assetRightFormStyles = ({ theme }) => css`
  position: absolute;
  right: 0;
  top: 0;
  height: 600px;

  @media (max-width: ${theme.breaks.huge}) {
    right: 0;
  }

  @media (max-width: ${theme.breaks.small}) {
    display: none;
  }
`;

const flagStyles = ({ theme }) => css`
  background: ${theme.colors.background};
  border-radius: 20px;
  padding: 3px 20px;
  color: ${theme.colors.white};
  opacity: 60%;
  font-size: ${theme.typography.xs};
  font-weight: 500;
  line-height: 24px;
  margin-left: 10px;
`;

const wrapperTitleStyles = () => css`
  display: flex;
  align-items: center;
  h1 {
    margin: 0;
  }

  margin-bottom: 13px;
`;

export const Wrapper = styled('div')(wrapperStyles);
export const TitleCheckout = styled('h1')(titleCheckoutStyles);
export const WrapperBanner = styled('div')(wrapperBannerStyles);

export const CoursesSection = styled('section')(coursesSectionStyles);
export const FilterSection = styled('section')(filterSectionStyles);

export const BootcampsSection = styled('section')(bootcampsSectionStyles);
export const SlideSection = styled('div')(slideSectionStyles);

export const CardCheckout = styled('div')(cardCheckoutStyles);
export const ButtonPurchase = styled(U.ButtonBlue)(buttonPurchaseStyles);
export const InfoText = styled('h1')(infoStyles);
export const CheckoutText = styled('h1')(checkoutTextStyles);
export const ImageCourse = styled('img')(imageCourseStyles);
export const WrapperIcon = styled('div')(wrapperIconStyles);
export const Price = styled(U.TitleShapes)(priceStyles);
export const PriceTotal = styled('h1')(priceTotalStyles);

export const CardInfo = styled('div')(cardInfoStyles);
export const ContinueBuying = styled(Link)(continueBuyingStyles);
export const WrapperEmptyCart = styled('div')(wrapperEmptyCart);
export const AssetRightForm = styled('img')(assetRightFormStyles);
export const Flag = styled('div')(flagStyles);
export const WrapperTitle = styled('div')(wrapperTitleStyles);

import styled from '@emotion/styled';
import { css } from '@emotion/react';
import * as U from '~/styles/utilities';

const cardStyles = ({ theme, mt, dark }) => css`
  position: relative;
  background: ${dark ? theme.colors.black2 : theme.colors.gray5};
  ${!dark && `border: 1px solid ${theme.colors.gray6}`};
  border-radius: 4px;
  opacity: 1;
  ${mt && `margin-top: ${mt}px`};

  p {
    color: ${theme.colors.white};
    font-size: ${theme.typography.xs};
    opacity: 60%;
    margin-bottom: 65px;
    margin-top: 25px;
    font-weight: 100;
    line-height: 22px;
  }
`;

const titleCardStyles = ({ theme }) => css`
  display: flex;
  align-items: center;
  width: 100%;
  font-weight: 500;
  padding: 20px 30px;
  background: ${theme.colors.lightblue};
  border-radius: 4px;

  p {
    color: ${theme.colors.background};
    font-size: ${theme.typography.xs};
  }

  svg {
    margin-right: 22px;
  }
`;

const cardContentStyles = () => css`
  padding: 15px 24px 24px 24px;
`;

const fullWidthStyles = () => css`
  width: 100%;
`;

const arrowImgStyles = () => css`
  position: absolute;
  bottom: -8px;
  right: -18px;
  width: 110px;

  @media (max-width: 599px) {
    display: none;
  }
`;

export const Card = styled('div')(cardStyles);
export const TitleCard = styled('div')(titleCardStyles);
export const CardContent = styled('div')(cardContentStyles);
export const CardButton = styled(U.Button)(fullWidthStyles);
export const ArrowImg = styled('img')(arrowImgStyles);

import styled from '@emotion/styled';

import { css } from '@emotion/react';

const svgStyles = () => css`
  height: 500px;

  @media (max-width: 650px) {
    height: 300px;
  }
`;

const nodeStyles = () => css`
  position: relative;
`;

const foreignObjectStyles = ({ theme, height }) => css`
  height: ${height};
  width: 270px;

  @media (max-width: 1130px) {
    display: none;
  }

  z-index: -1;
`;

const tooltipStyles = ({ theme }) => css`
  display: flex;
  align-items: center;
  color: ${theme.colors.white};
  font-size: ${theme.typography.xs};
  font-weight: bold;
  background: ${theme.colors.background};
  border: solid 1px ${theme.colors.blue};
  border-radius: 4px;
  padding: 10px 10px 10px 0;
  z-index: -1;
`;

const circleStyles = ({ theme }) => css`
  @media (min-width: ${theme.breaks.medium1}) {
    cursor: pointer;
  }
  box-shadow: 0px 3px 9px 15px #19f7ff;
  fill: #19f7ff;
  transition: 0.5s;

  &:hover {
    transition: 0.5s;
    -webkit-filter: drop-shadow(0px 3px 15px rgba(25, 247, 255));
    filter: drop-shadow(0px 3px 15px rgba(25, 247, 255));
  }
  z-index: 9999;
`;

const imageStyles = ({ active }) => css`
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: ${active ? 1 : 0};
  transition: opacity 0.5s ease-out;
`;

export const CircleContainer = styled.svg``;

export const Svg = styled('svg')(svgStyles);
export const Node = styled('svg')(nodeStyles);
export const ForeignObject = styled('foreignObject')(foreignObjectStyles);
export const Tooltip = styled('div')(tooltipStyles);
export const Circle = styled('circle')(circleStyles);
export const Image = styled('img')(imageStyles);

import styled from '@emotion/styled';
import { css } from '@emotion/react';

const teachersSectionStyles = ({ theme }) => css`
  display: flex;
  margin-bottom: 230px;

  @media (max-width: ${theme.breaks.medium}) {
    padding-top: 100px;
    margin-bottom: 100px;
  }
`;

export const TeachersSection = styled('div')(teachersSectionStyles);

import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { motion } from 'framer-motion';

const titleStyles = ({ theme, blue }) => css`
  color: ${blue ? theme.colors.blue : theme.colors.white};
  line-height: 40px;
`;

const smallTitleWrapperStyles = ({ theme }) => css`
  color: ${theme.colors.white};

  font-size: 25px;
  line-height: 30px;
  font-weight: 300;

  @media (max-width: ${theme.breaks.medium}) {
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 30px;
  }

  span {
    color: ${theme.colors.lightblue};
  }
`;

const defaultSectionStyles = () => css`
  margin: 30px 0 200px 0;
`;

const learnCardStyles = ({ theme }) =>
  css`
    padding-right: 80px;
    margin-bottom: 50px;

    @media (max-width: ${theme.breaks.medium}) {
      padding: 0;
    }

    svg {
      margin-bottom: 22px;
    }
  `;

const boxgeneralVisionStyles = ({ theme }) => css`
  background: ${theme.colors.gray8};
  border-radius: 8px;
  padding: 20px 30px;
  border: 1px solid #2e3436;

  height: 6rem;
  @media (max-width: 560px) {
    height: auto;
  }
`;

const iconContainerStyles = ({ theme, rotate }) => css`
  border: solid 2px ${theme.colors.gray};
  width: 152px;
  height: 152px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: relative;

  svg {
    margin: 0;
    ${rotate &&
    css`
      transform: rotate(-45deg);
    `};
  }
`;

const whiteBackgroundStyles = ({ theme }) => css`
  position: relative;

  background: ${theme.colors.background2};
`;

const buttonStyles = ({ theme }) => css`
  background: ${theme.colors.lightblue2};
  font-family: 'Montserrat', sans-serif;
  border: 0;
  font-size: 13px;
  border-radius: 12px;
  padding: 15px 30px;
  text-transform: uppercase;
  color: ${theme.colors.white};
  font-weight: bold;
`;

export const Title = styled('h1')(titleStyles);
export const WhiteBackground = styled('div')(whiteBackgroundStyles);
export const SmallTitleWrapper = styled('div')(smallTitleWrapperStyles);
export const DefaultSection = styled('div')(defaultSectionStyles);
export const LearnCard = styled('div')(learnCardStyles);
export const BoxGeneralVision = styled('div')(boxgeneralVisionStyles);

export const IconsContainer = styled('div')(iconContainerStyles);
export const Button = styled(motion.button)(buttonStyles);

import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { Formik } from 'formik';

import * as S from './styled';
import * as U from '~/styles/utilities';
import * as F from '~/styles/form';
import { phoneWithDDDMask } from '~/utils/remask';
import api from '~/services/api';
import { Toast, Loading } from '~/components';

import validations from './validations';

const InCompanyForm = () => {
  const [loading, setLoading] = useState(false);

  const initialValues = {
    name: '',
    email: '',
    company: '',
    city: '',
    phone: '',
    quantity: '',
    category: '',
    description: '',
  };

  const handleSubmitForm = async (data, { resetForm }) => {
    try {
      setLoading(true);
      await api.post('/contact/', data);
      Toast.success('Enviado com sucesso!');
      setLoading(false);
      resetForm(initialValues);
    } catch (err) {
      setLoading(false);
      Toast.error('Algo deu errado, tente novamente mais tarde');
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmitForm}
      validationSchema={validations()}
    >
      {({
        handleSubmit,
        handleBlur,
        handleChange,
        values,
        errors,
        touched,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <F.Form>
            <F.FormContent>
              <U.TitleShapes>TEM INTERESSE?</U.TitleShapes>
              <S.Title>Fale conosco!</S.Title>
              <U.Gap size={20} />
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <F.Label>Nome completo</F.Label>
                  <F.Input
                    type="text"
                    placeholder="Digite seu nome"
                    name="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                  />
                  {errors.name && touched.name ? (
                    <F.Hint error={!!errors.name && touched.name}>
                      {errors.name}
                    </F.Hint>
                  ) : null}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <F.Label>E-mail</F.Label>
                  <F.Input
                    type="email"
                    placeholder="Digite seu e-mail"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                  {errors.email && touched.email ? (
                    <F.Hint error={!!errors.email && touched.email}>
                      {errors.email}
                    </F.Hint>
                  ) : null}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <F.Label>Empresa</F.Label>
                  <F.Input
                    type="text"
                    placeholder="Digite o nome da empresa"
                    name="company"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.company}
                  />
                  {errors.company && touched.company ? (
                    <F.Hint error={!!errors.company && touched.company}>
                      {errors.company}
                    </F.Hint>
                  ) : null}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <F.Label>Cidade</F.Label>
                  <F.Input
                    type="text"
                    placeholder="Digite sua cidade"
                    name="city"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.city}
                  />
                  {errors.city && touched.city ? (
                    <F.Hint error={!!errors.city && touched.city}>
                      {errors.city}
                    </F.Hint>
                  ) : null}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <F.Label>Telefone</F.Label>
                  <F.Input
                    type="text"
                    placeholder="Digite o telefone"
                    name="phone"
                    onChange={(e) =>
                      setFieldValue('phone', phoneWithDDDMask(e.target.value))
                    }
                    onBlur={handleBlur}
                    value={values.phone}
                  />
                  {errors.phone && touched.phone ? (
                    <F.Hint error={!!errors.phone && touched.phone}>
                      {errors.phone}
                    </F.Hint>
                  ) : null}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <F.Label>Quantidade de pessoas</F.Label>
                  <F.Input
                    type="number"
                    placeholder="Digite a quantidade de pessoas"
                    name="quantity"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.quantity}
                  />
                  {errors.quantity && touched.quantity ? (
                    <F.Hint error={!!errors.quantity && touched.quantity}>
                      {errors.quantity}
                    </F.Hint>
                  ) : null}
                </Grid>
                <Grid item xs={12}>
                  <F.Label>Qual treinamento?</F.Label>
                  <F.Select
                    name="category"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.category}
                  >
                    <option value="">Selecione uma opção de treinamento</option>
                    <option value="PowerBI">PowerBI</option>
                    <option value="Python">Python</option>
                    <option value="Excel">Excel</option>
                    <option value="Outros">Outros</option>
                  </F.Select>
                  {errors.category && touched.category ? (
                    <F.Hint error={!!errors.category && touched.category}>
                      {errors.category}
                    </F.Hint>
                  ) : null}
                </Grid>

                <Grid item xs={12}>
                  <F.Label>Do que você precisa?</F.Label>
                  <F.TextArea
                    type="text"
                    placeholder="Descrição"
                    rows={8}
                    name="description"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.description}
                  />
                  {errors.description && touched.description ? (
                    <F.Hint error={!!errors.description && touched.description}>
                      {errors.description}
                    </F.Hint>
                  ) : null}
                </Grid>
                <Grid item xs={12} container justify="flex-end">
                  <F.ButtonForm
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className="flex-center"
                  >
                    {loading ? <Loading /> : 'ENVIAR'}
                  </F.ButtonForm>
                </Grid>
              </Grid>
            </F.FormContent>
          </F.Form>
        </form>
      )}
    </Formik>
  );
};

export default InCompanyForm;

import styled from '@emotion/styled';
import { css } from '@emotion/react';

import * as U from '~/styles/utilities';

const aboutCourseSectionStyles = ({ theme }) => css`
  margin-bottom: 230px;

  @media (max-width: ${theme.breaks.medium1}) {
    margin-bottom: 100px;
  }
`;

const wrapperTitleAbout = ({ theme }) => css`
  margin-bottom: 27px;

  p {
    font-weight: 600;
    color: ${theme.colors.blue};
    font-size: ${theme.typography.md};
    margin-left: 16px;
  }

  @media (max-width: ${theme.breaks.medium}) {
    flex-direction: column;
    align-items: flex-start;

    p {
      margin: 10px 0 0 0;
    }
  }
`;

const alignCenterStyles = () => css`
  display: flex;
  align-items: center;
`;

const containerAbout = ({ theme }) => css`
  padding-right: 80px;

  @media (max-width: ${theme.breaks.large}) {
    padding: 0;
  }
`;

const contentStyles = ({ theme }) => css`
  * {
    color: ${theme.colors.white};
  }

  font-size: ${theme.typography.sm};
  opacity: 60%;
  line-height: 24px;
  font-weight: 600;

  ul {
    list-style: inside;
    margin-bottom: 16px;
  }

  p {
    margin-bottom: 16px;
  }
`;

const facilitatorSectionStyles = () => css`
  margin-top: 72px;
`;

const facilitatorTitleStyles = ({ theme }) => css`
  font-size: ${theme.typography.xl2};
  color: ${theme.colors.white};
  font-weight: bold;
  margin-bottom: 25px;
`;

const facilitatorCardStyles = ({ theme }) => css`
  display: flex;

  img {
    border-radius: 50%;
    width: 68px;
    height: 68px;
    object-fit: cover;
    margin-right: 34px;
  }
`;

const facilitatorInfoStyles = () => css``;

const facilitatorNameStyles = ({ theme }) => css`
  font-size: 24px;
  color: ${theme.colors.blue};

  svg {
    margin-left: 16px;
    transition: all 0.3s cubic-bezier(0.25, 0.39, 0.39, 2.01);
  }

  svg:hover {
    transform: scale(1.2);
    transition: all 0.3s cubic-bezier(0.25, 0.39, 0.39, 2.01);
  }
`;

const infoCourse = () => css`
  background: #4b5253;
  border: 1px solid #c2c2c2;
  border-radius: 4px;
  opacity: 1;
  padding: 24px 35px;
  margin-bottom: 24px;
`;

const containerInfoCardStyles = ({ theme }) => css`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${theme.breaks.medium}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const priceContainer = ({ theme }) => css`
  @media (max-width: ${theme.breaks.medium}) {
    margin-bottom: 20px;
  }
`;

const coursePrice = ({ theme, previous }) => css`
  font-size: ${previous ? '24px' : '32px'};
  color: ${previous ? theme.colors.blue : theme.colors.white};
  font-weight: ${previous ? 500 : 'bold'};

  span {
    font-size: ${theme.typography.xl2};
  }
`;

const textUnderPrice = ({ theme }) => css`
  font-size: ${theme.typography.md};
  color: ${theme.colors.white};
  opacity: 60%;
  line-height: 24px;
  font-weight: 500;
`;

const splitStyles = ({ theme }) => css`
  height: 80px;
  width: 2px;
  background-color: ${theme.colors.white};
  opacity: 60%;

  @media (max-width: ${theme.breaks.medium}) {
    display: none;
  }
`;

const fullWidthStyles = ({ theme }) => css`
  width: 100%;
`;

const buttonPurchaseMargin = () => css`
  margin-top: 24px;
`;

const titleCollapsedStyles = ({ theme }) => css`
  font-size: ${theme.typography.xs};
  color: ${theme.colors.white};

  font-weight: 400;
`;

const containerCollapsedStyles = ({ theme }) => css`
  border-bottom: 1px solid #fff;
  display: flex;
  align-items: center;
  margin-bottom: ${theme.spacing.s1};
`;

const unlockedBarStyles = () => css`
  margin-left: 8px;
  background: #4cc02369;
  border: 0.5px solid #4cc023;
  border-radius: 2px;
  color: #fff;
  font-weight: 500;
  font-size: 8px;
  padding: 5px;

  display: flex;
  align-items: center;
  height: 15px;

  svg {
    margin-right: 5px;
  }
`;

const titleModule = ({ theme }) => css`
  font-size: 32px;
  color: ${theme.colors.blue};
  letter-spacing: 2.22px;
  font-weight: bold;
`;

export const TitleModule = styled('h1')(titleModule);
export const AboutCourseSection = styled('div')(aboutCourseSectionStyles);
export const WrapperTitleAbout = styled('div')(
  wrapperTitleAbout,
  alignCenterStyles
);
export const ContainerAbout = styled('div')(containerAbout);
export const FacilitatorSection = styled('div')(facilitatorSectionStyles);
export const FacilitatorTitle = styled('h1')(facilitatorTitleStyles);
export const FacilitatorCard = styled('div')(facilitatorCardStyles);
export const FacilitatorInfo = styled('div')(facilitatorInfoStyles);
export const FacilitatorName = styled('h1')(
  facilitatorNameStyles,
  alignCenterStyles
);
export const InfoCourseCard = styled('div')(infoCourse);
export const ContainerInfoCard = styled('div')(containerInfoCardStyles);
export const PriceContainer = styled('div')(priceContainer);
export const CoursePrice = styled('h1')(coursePrice);
export const TextUnderPrice = styled('h1')(textUnderPrice);
export const Split = styled('div')(splitStyles);
export const ButtonPurchase = styled(U.ButtonBlue)(
  fullWidthStyles,
  buttonPurchaseMargin
);

export const TitleCollapsed = styled('p')(titleCollapsedStyles);
export const ContainerCollapsed = styled('p')(containerCollapsedStyles);
export const UnlockedText = styled('p')(unlockedBarStyles);
export const Content = styled('div')(contentStyles);

import { css } from '@emotion/react';
import theme from './theme';

const globalStyles = css`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  html,
  body,
  #root {
    font-size: 62.5%;
    font: 16px ${theme.font.montserrat};
    background-color: ${theme.colors.background};
    -webkit-font-smoothing: antialised;

    overflow-x: clip;
  }

  img {
    object-fit: contain;
  }

  ${'' /* html {
    scroll-behavior: smooth;
  } */}

  a {
    text-decoration: none;
  }

  button {
    line-height: 1.6;
    @media (min-width: ${theme.breaks.medium1}) {
      cursor: pointer;
    }
  }

  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  ::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }
  ::-webkit-scrollbar-thumb {
    background: #3c4142;
    border: 0px none #111;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #3c4142;
  }
  ::-webkit-scrollbar-thumb:active {
    background: #3c4142;
  }
  ::-webkit-scrollbar-track {
    background: #111;
    border: 0px none #111;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track:hover {
    background: #111;
  }
  ::-webkit-scrollbar-track:active {
    background: #111;
  }
  ::-webkit-scrollbar-corner {
    background: transparent;
  }

  ${'' /* slide */}

  .react-multiple-carousel__arrow {
    position: absolute;
    outline: 0;
    transition: all 0.5s;
    border-radius: 35px;
    z-index: 1000;
    border: 0;
    margin-top: -15px;
    background: rgb(25 247 255 / 30%);
    min-width: 45px;
    min-height: 45px;
    opacity: 1;
    @media (min-width: ${theme.breaks.medium1}) {
      cursor: pointer;
    }
  }

  .react-multiple-carousel__arrow::before {
    font-size: 28px;
    color: #fff;
    display: block;
    font-weight: bolder;
    font-family: revicons;
    text-align: center;
    z-index: 2;
    position: relative;
  }

  .react-multiple-carousel__arrow--right {
    right: calc(4% + -36px);

    @media (max-width: ${theme.breaks.large}) {
      right: calc(4% + -5px);
    }
  }

  .react-multiple-carousel__arrow--left {
    left: calc(4% + -36px);
    @media (max-width: ${theme.breaks.large}) {
      left: calc(4% + -5px);
    }
  }

  /* toast */

  .toast-container {
    color: ${theme.colors.gray} !important;
    font-size: 16px;
    border-radius: 50px !important;
    background: ${theme.colors.white} !important;
    border: 1px solid ${theme.colors.gray2} !important;
    box-sizing: border-box;
  }

  .Toastify__toast {
    box-shadow: none;
    padding: 0.8rem 1.6rem;
    min-height: 50px;
  }

  .Toastify__toast-body {
    padding: 0;
  }

  .Toastify__toast-container {
    width: auto;
  }

  @media (max-width: 480px) {
    .Toastify__toast-container {
      width: 100%;
      margin-top: 2rem;
    }
  }
`;

export default globalStyles;

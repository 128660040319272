import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Link } from 'react-scroll';
import { motion } from 'framer-motion';
import { pulsate } from '~/styles/animations';
import banner from '~/assets/img/consultancy-bkg.png';

const wrapperStyles = () => css`
  position: relative;
`;

const bannerStyles = ({ theme }) => css`
  background: url(${banner});
  background-size: 100%, cover;
  height: 100vh;
  min-height: 700px;
  background-repeat: no-repeat;
  background-position: center;

  @media (max-width: ${theme.breaks.huge}) {
    min-height: 100vh;
    width: 100%;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-position: unset;
  }
`;

const wrapperBannerStyles = ({ theme }) => css`
  min-height: 100vh;

  @media (max-width: ${theme.breaks.medium}) {
    min-height: 90vh;
  }

  @media (max-width: ${theme.breaks.medium1}) {
    justify-content: flex-start;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 100px;
`;

const contentBannerStyles = ({ theme }) => css`
  display: flex;
  align-items: flex-start;
  text-align: left;
  z-index: 1;
`;

const marginTextBannerStyles = ({ theme }) => css`
  @media (min-width: ${theme.breaks.large}) {
    margin-top: 80px;
  }
`;

const imageBannerStyles = ({ theme }) => css`
  width: 95%;
  object-fit: contain;

  @media (max-width: ${theme.breaks.large}) {
    min-width: auto;
    margin-top: -88px;
  }
`;

const moreKnowledgeStyles = ({ theme }) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px;
  @media (min-width: ${theme.breaks.medium1}) {
    cursor: pointer;
  }

  span {
    position: relative;
    background: #234950;
    display: flex;
    margin-left: 15px;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    border-radius: 50%;
  }

  span:before {
    content: '';
    border: 13px solid #244950;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    position: absolute;
    left: -13px;
    bottom: -13px;
    animation: ${pulsate} 1.6s ease-out;
    animation-iteration-count: infinite;
    opacity: 0;
    z-index: 99;
  }

  @media (max-width: ${theme.breaks.large}) {
    span {
      height: 30px;
      width: 30px;
    }

    span:before {
      height: 30px;
      width: 30px;
    }
  }

  color: ${theme.colors.blue};
  font-size: ${theme.typography.md};
  font-weight: 500;
`;

const introSectionStyles = () => css`
  display: flex;
  margin-bottom: 90px;
`;

const efficiencyTitle = ({ theme }) => css`
  color: ${theme.colors.background};
  font-size: ${theme.typography.xl2};
  font-weight: 600;
  margin-bottom: 60px;
`;

const efficiencySectionStyles = () => css`
  padding-top: 140px;
`;

const depositionCardStyles = ({ theme }) => css`
  display: flex;

  img {
    border-radius: 50%;
    width: 68px;
    height: 68px;
    object-fit: cover;
    margin-right: 34px;
  }
`;

const depositionInfoStyles = ({ theme }) => css``;

const depositionNameStyles = ({ theme }) => css`
  font-size: ${theme.typography.sm};
  color: ${theme.colors.blue};
  font-weight: bold;

  svg {
    margin-left: 16px;
  }
`;

const depositionInfoTextStyles = ({ theme }) => css`
  font-size: 10px;
  color: ${theme.colors.background};
  font-weight: bold;
  opacity: 60%;
  margin: 5px 0;
`;

const alignCenterStyles = () => css`
  display: flex;
  align-items: center;
`;

const quotesImgStyles = () => css`
  margin-top: 20px;
`;

const titleBannerStyles = ({ theme }) => css`
  color: ${theme.colors.white};
  font-size: 83px;
  font-weight: bold;
  letter-spacing: 13.7px;
  line-height: 66px;

  margin-bottom: 40px;

  @media (max-width: ${theme.breaks.large}) {
    font-size: ${theme.typography.xl3};
    letter-spacing: 0;
    margin-bottom: 0;
  }
`;

const backgroundFooterStyles = ({ theme }) => css`
  position: relative;

  background: ${theme.colors.black1};
`;

const whiteBackgroundStyles = ({ theme }) => css`
  position: relative;

  background: ${theme.colors.background2};
`;

const assetBannerBackgroundStyles = ({ theme }) => css`
  position: absolute;

  background: ${theme.colors.background};
  mix-blend-mode: multiply;
  opacity: 0.9;
  height: 260px;
  width: 49%;

  @media (max-width: ${theme.breaks.medium1}) {
    display: none;
  }
`;

const contentCenterStyles = () => css`
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
`;

const buttonBannerStyles = ({ theme }) => css`
  background: ${theme.colors.background};
  border: 1px solid ${theme.colors.white};
  opacity: 1;
  height: 63px;
  width: 63px;

  @media (max-width: ${theme.breaks.medium1}) {
    margin-top: 40px;
  }

  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    color: ${theme.colors.white};
  }
`;

const titleBigSectionStyles = ({ theme }) => css`
  color: ${theme.colors.background};
  font-size: 56px;
  line-height: 57px;

  @media (max-width: ${theme.breaks.large}) {
    font-size: ${theme.typography.xl3};
    line-height: 34px;
  }
`;

const learnSectionStyles = () => css`
  padding: 100px 0;
`;

const textStyles = ({ theme }) => css`
  font-size: ${theme.typography.sm};
  color: ${theme.colors.background};
  line-height: 24px;
  font-weight: 600;
`;

const boxNumberStyles = ({ theme, right0, noline }) => css`
  position: relative;
  height: 63px;
  width: 63px;
  background-color: ${theme.colors.background};
  margin-bottom: 40px;

  span {
    position: absolute;

    right: ${right0 ? '0' : '-20px'};
    bottom: -40px;

    font-size: 91px;
    color: ${theme.colors.white};
    line-height: 111px;
    font-weight: 600;
    text-shadow: 0px 3px 6px #00000029;
  }

  @media (min-width: ${theme.breaks.medium}) {
    ${!noline &&
    css`
      &:after {
        content: '';
        position: absolute;
        width: 2px;
        right: 49px;
        top: 75px;
        height: 115px;
        background: ${theme.colors.gray};
      }
    `}
  }
`;

const boxImageStyles = ({ theme }) => css`
  position: relative;
  background-color: ${theme.colors.white};
  border: solid 3px ${theme.colors.background};
  height: 479px;
  border-radius: 4px;
  width: 90%;

  @media (max-width: ${theme.breaks.medium}) {
    height: 300px;
  }

  &:after {
    content: '';
    position: absolute;
    top: -43px;
    left: -33px;
    background-color: ${theme.colors.background};
    height: 479px;
    border-radius: 4px;
    width: 100%;

    @media (max-width: ${theme.breaks.medium}) {
      height: 300px;
    }
  }
`;

const boxImageSection2 = ({ theme }) => css`
  position: relative;
  background-color: ${theme.colors.background};
  height: 100%;
  border-radius: 4px;
  width: 90%;

  @media (max-width: ${theme.breaks.medium}) {
    margin-top: 60px;
    height: 300px;
    width: 100%;
  }
`;

export const Wrapper = styled('div')(wrapperStyles);
export const TitleBanner = styled('h1')(titleBannerStyles);
export const TitleBig = styled('h1')(titleBigSectionStyles);
export const Text = styled('p')(textStyles);

export const WrapperBanner = styled('div')(wrapperBannerStyles);
export const ContentBanner = styled('div')(contentBannerStyles);
export const MarginTextBanner = styled('div')(marginTextBannerStyles);
export const ImageBanner = styled('img')(imageBannerStyles);
export const MoreKnowledge = styled(Link)(moreKnowledgeStyles);
export const IntroSection = styled('section')(introSectionStyles);

export const EfficiencySection = styled('div')(efficiencySectionStyles);
export const EfficiencyTitle = styled('h1')(efficiencyTitle);

export const DepositionCard = styled('div')(depositionCardStyles);
export const DepositionInfo = styled('div')(depositionInfoStyles);
export const DepositionInfoText = styled('h1')(depositionInfoTextStyles);
export const DepositionName = styled('h1')(
  depositionNameStyles,
  alignCenterStyles
);
export const Quotes = styled('img')(quotesImgStyles);

export const BackgroundFooter = styled('div')(backgroundFooterStyles);
export const WhiteBackground = styled('div')(whiteBackgroundStyles);
export const Container = styled('div')(bannerStyles);
export const AssetBannerBackground = styled('div')(assetBannerBackgroundStyles);
export const ContentCenter = styled('div')(contentCenterStyles);
export const ButtonBanner = styled(motion.button)(buttonBannerStyles);
export const LearnSection = styled('div')(learnSectionStyles);

export const BoxNumber = styled('div')(boxNumberStyles);
export const BoxImage = styled('div')(boxImageStyles);
export const BoxImageSection2 = styled('div')(boxImageSection2);

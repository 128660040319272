import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { motion } from 'framer-motion';

const mainStyles = ({ theme }) => css`
  height: 100vh;
  display: flex;
  align-items: center;
`;

const titleStyles = ({ theme }) => css`
  font-size: 70px;
  color: ${theme.colors.lightblue};
  letter-spacing: 2.22px;
  font-weight: bold;
`;

const logoModalStyles = ({ theme }) => css`
  height: 50px;

  @media (max-width: ${theme.breaks.medium}) {
    display: none;
  }
`;

const titleModalStyles = ({ theme }) => css`
  font-size: ${theme.typography.xl2};
  color: ${theme.colors.lightblue};
  line-height: 25px;
`;

const buttonStyles = ({ theme }) => css`
  background: ${theme.colors.lightblue2};
  font-family: 'Montserrat', sans-serif;
  border: 0;
  font-size: 13px;
  border-radius: 12px;
  padding: 15px 30px;
  text-transform: uppercase;
  color: ${theme.colors.white};
  font-weight: bold;
  width: 100%;
`;

const cardStyles = ({ theme }) => css`
  background: linear-gradient(180deg, #3a4547, rgba(18, 18, 20, 0));

  border-radius: 8px;
  padding: 30px;

  width: 100%;
  height: auto;
`;

export const Main = styled('div')(mainStyles);
export const Title = styled('h1')(titleStyles);
export const LogoModal = styled('img')(logoModalStyles);
export const TitleModal = styled('h1')(titleModalStyles);
export const Button = styled(motion.button)(buttonStyles);
export const Card = styled('div')(cardStyles);

import React from 'react';
import { toast as custom } from 'react-toastify';
import { FaCheckCircle } from 'react-icons/fa';
import { GoAlert } from 'react-icons/go';
import * as S from './styled';

import 'react-toastify/dist/ReactToastify.css';

import theme from '~/styles/theme';

const options = {
  position: 'top-right',
  hideProgressBar: true,
  closeButton: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

custom.configure();

const toast = {
  warning(message, duration) {
    return custom.warning(
      <S.WrapperToast>
        <GoAlert
          color={theme.colors.yellow}
          size={18}
          style={{ marginRight: 10 }}
        />

        {message}
      </S.WrapperToast>,
      {
        ...options,
        autoClose: duration || 4000,
        className: 'toast-container',
      }
    );
  },
  success(message, duration) {
    return custom.success(
      <S.WrapperToast>
        <FaCheckCircle
          color={theme.colors.green}
          size={18}
          style={{ marginRight: 10 }}
        />

        {message}
      </S.WrapperToast>,
      {
        ...options,
        autoClose: duration || 4000,
        className: 'toast-container',
      }
    );
  },
  error(message, duration) {
    return custom.error(
      <S.WrapperToast>
        <GoAlert
          color={theme.colors.red}
          size={18}
          style={{ marginRight: 10 }}
        />

        {message}
      </S.WrapperToast>,
      {
        ...options,
        autoClose: duration || 4000,
        className: 'toast-container',
      }
    );
  },
};

export default toast;

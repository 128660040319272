import styled from '@emotion/styled';
import { css } from '@emotion/react';

const titleStyles = ({ theme }) => css`
  color: ${theme.colors.white};
  line-height: 43px;
`;

const learnSectionStyles = () => css`
  margin: 75px 0 110px 0;
`;

const learnCardStyles = ({ theme }) =>
  css`
    padding-right: 80px;
    margin-bottom: 50px;

    @media (max-width: ${theme.breaks.medium}) {
      padding: 0;
    }

    svg {
      margin-bottom: 22px;
    }
  `;

const boxgeneralVisionStyles = ({ theme }) => css`
  background: ${theme.colors.gray8};
  border-radius: 8px;
  padding: 20px 30px;
  border: 1px solid #2e3436;

  height: 7.5rem;
  @media (max-width: 560px) {
    height: auto;
  }
`;

export const Title = styled('h1')(titleStyles);
export const LearnSection = styled('div')(learnSectionStyles);
export const LearnCard = styled('div')(learnCardStyles);
export const BoxGeneralVision = styled('div')(boxgeneralVisionStyles);

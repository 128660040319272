import styled from '@emotion/styled';
import { css } from '@emotion/react';

const mainStyles = ({ theme }) => css`
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: ${theme.breaks.large}) {
    padding-top: 60px;
    ${'' /* height: 100vh; */}
  }
`;

const titleStyles = ({ theme }) => css`
  font-size: 70px;
  color: ${theme.colors.lightblue};
  letter-spacing: 2.22px;
  font-weight: bold;
`;

export const Main = styled('div')(mainStyles);
export const Title = styled('h1')(titleStyles);

import styled from '@emotion/styled';
import { css } from '@emotion/react';

import Grid from '@material-ui/core/Grid';

import * as U from '~/styles/utilities';

const cardStyles = ({ theme }) => css`
  background: ${theme.colors.gray5};
  border: 1px solid ${theme.colors.gray6};
  border-radius: 4px;
  padding: 32px 24px;
`;

const infoStyles = ({ theme, noBorder }) => css`
  color: ${theme.colors.white};
  font-size: ${theme.typography.md};
  font-weight: 500;
  line-height: 24px;
  text-transform: uppercase;

  span {
    color: ${theme.colors.lightblue};
  }

  ${!noBorder &&
  `border-bottom: 2px solid ${theme.colors.gray6}; padding-bottom: 24px;`};
`;

const checkoutTextStyles = ({ theme }) => css`
  color: ${theme.colors.white};
  opacity: 60%;
  font-size: ${theme.typography.xs};
  font-weight: 500;
  line-height: 24px;
  margin: 27px 0 32px;
`;

const priceStyles = ({ theme, white }) => css`
  font-size: 16px;
  ${white && `color: ${theme.colors.white}`}
`;

const coursesContainerStyles = () => css`
  margin-bottom: 70px;
`;

const totalSectionStyles = ({ theme }) => css`
  border-top: 2px solid ${theme.colors.gray6};
  border-bottom: 2px solid ${theme.colors.gray6};
  padding: 24px 0;
  margin-bottom: 30px;
`;

const totalStyles = ({ theme }) => css`
  color: ${theme.colors.white};
  font-size: 24px;
  font-weight: bold;
  line-height: 43px;
`;

const buttonStyles = ({ full, padding }) => css`
  border-radius: 4px;
  padding: ${padding ? `${padding}` : `12px 57px`};
  ${full && `width: 100%;`}
`;

const gridPurchaseItemsStyles = ({ theme }) => css`
  @media (max-width: ${theme.breaks.medium}) {
    flex-direction: column;
  }

  padding-bottom: 15px;
`;

const gridAlign = ({ theme }) => css`
  text-align: end;
  @media (max-width: ${theme.breaks.medium}) {
    text-align: left;
  }
`;

const flagStyles = ({ theme }) => css`
  background: ${theme.colors.background};
  border-radius: 20px;
  padding: 3px 20px;
  color: ${theme.colors.white};
  opacity: 60%;
  font-size: ${theme.typography.xs};
  font-weight: 500;
  justify-content: center;
  display: flex;
  line-height: 24px;
`;

export const InfoText = styled('h1')(infoStyles);
export const CheckoutText = styled('h1')(checkoutTextStyles);

export const Price = styled(U.TitleShapes)(priceStyles);

export const Card = styled('div')(cardStyles);
export const CoursesContainer = styled('div')(coursesContainerStyles);
export const TotalSection = styled('section')(totalSectionStyles);
export const Total = styled('h1')(totalStyles);
export const Button = styled(U.ButtonBlue)(buttonStyles);

export const GridPurchaseItems = styled(Grid)(gridPurchaseItemsStyles);
export const GridAlign = styled(Grid)(gridAlign);
export const Flag = styled('div')(flagStyles);

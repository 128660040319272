import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { Formik } from 'formik';
import * as F from '../../../../styles/form';
import * as U from '~/styles/utilities';
import * as S from '../styled';
import { useStateContext } from '~/hooks//context/modules/StatesContext';
import { useUserContext } from '~/hooks//context/modules/UserContext';
import api from '../../../../services/api';
import Loading from '~/components/Loading';
import validations from './validations';
import logo from '~/assets/img/logo.png';
import Toast from '../../../Alert';

const SignUp = () => {
  const { toogleLogin, onCloseSignUp } = useStateContext();
  const { signIn } = useUserContext();

  const [loading, setLoading] = useState(false);
  // form

  const initialValues = {
    name: '',
    email: '',
    password: '',
    confirm_password: '',
  };

  const [errorField, setErrorField] = useState({
    email: {
      message: null,
      value: null,
    },
  });

  const handleSubmitForm = async (data, e) => {
    try {
      setLoading(true);
      await api.post('/user/register/', data);

      const signInData = {
        username: data.email,
        password: data.password,
      };

      signIn(signInData);

      // Toast.success('Cadastro feito com sucesso!');
      setLoading(false);
      onCloseSignUp();
    } catch (err) {
      const field = err.response.data;

      setErrorField({
        email: {
          message: field.email,
          value: data.email,
        },
      });

      setLoading(false);
    }
    e.target.reset();
  };

  return (
    <>
      <Grid container justify="space-between" alignItems="center">
        <Grid item>
          <S.TitleModal>Faça sua conta</S.TitleModal>
        </Grid>
        <Grid item>
          <S.LogoModal src={logo} alt="logo" />
        </Grid>
      </Grid>
      <U.Gap size={20} />
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmitForm}
        validationSchema={validations()}
      >
        {({
          handleSubmit,
          handleBlur,
          handleChange,
          values,
          errors,
          touched,
          setFieldValue,
        }) => (
          <>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <F.Label>Nome</F.Label>
                  <F.Input
                    type="text"
                    placeholder="Digite seu nome"
                    name="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                  />
                  {errors.name && touched.name ? (
                    <F.Hint error={!!errors.name && touched.name}>
                      {errors.name}
                    </F.Hint>
                  ) : null}
                </Grid>
                <Grid item xs={12}>
                  <F.Label>E-mail</F.Label>
                  <F.Input
                    type="email"
                    placeholder="Digite seu e-mail"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                  {errors.email && touched.email ? (
                    <F.Hint error={!!errors.email && touched.email}>
                      {errors.email}
                    </F.Hint>
                  ) : null}

                  {errorField.email.message !== null &&
                    errorField.email.value === values.email && (
                      <F.Hint error>{errorField.email.message.join()}</F.Hint>
                    )}
                </Grid>

                <Grid item xs={12} sm={6}>
                  <F.Label>Senha</F.Label>
                  <F.Input
                    type="password"
                    placeholder="Digite sua senha"
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                  />
                  {errors.password && touched.password ? (
                    <F.Hint error={!!errors.password && touched.password}>
                      {errors.password}
                    </F.Hint>
                  ) : null}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <F.Label>Confirmar senha</F.Label>
                  <F.Input
                    type="password"
                    placeholder="Confirme sua senha"
                    name="confirm_password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.confirm_password}
                  />
                  {errors.confirm_password && touched.confirm_password ? (
                    <F.Hint
                      error={
                        !!errors.confirm_password && touched.confirm_password
                      }
                    >
                      {errors.confirm_password}
                    </F.Hint>
                  ) : null}
                </Grid>
                <Grid item>
                  <S.InfoModal>
                    Ao criar a sua conta você concorda com nossos termos de uso
                    e
                  </S.InfoModal>

                  <S.InfoModal>
                    privacidade:{' '}
                    <a href="#termos-de-uso">Termos e Privacidade</a>
                  </S.InfoModal>
                </Grid>

                <Grid
                  item
                  xs={12}
                  container
                  justify="flex-end"
                  style={{ margin: '38px 0 30px 0px' }}
                >
                  <U.ButtonBlue
                    // whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    full
                    className="flex-center"
                  >
                    {loading ? <Loading /> : 'CRIAR CONTA'}
                  </U.ButtonBlue>
                </Grid>

                <Grid container justify="center">
                  <S.InfoModal>Já tem uma conta? </S.InfoModal>
                  <S.InfoModal onClick={() => toogleLogin()}>
                    <span>Faça o login</span>
                  </S.InfoModal>
                </Grid>
              </Grid>
            </form>
          </>
        )}
      </Formik>
    </>
  );
};

export default SignUp;

import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { appearFromBottom } from '~/styles/animations';

const modalStyles = ({ noBackground }) => css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${noBackground ? '' : 'rgba(0, 0, 0, 0.6)'};
  z-index: 999;
  overflow-y: auto;
  align-items: center;
  justify-content: center;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const modalContainerStyles = ({ size, fullresp }) => css`
  padding-top: 5rem;
  padding: 2rem;
  margin: auto;
  margin-top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  @media (min-width: 769px) {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    ${size ? `width: ${size}rem` : 'width: 40rem'};
  }

  @media (max-width: 768px) {
    ${fullresp ? `margin-top: 4rem!important;` : 'margin-top: 10rem;'};
  }
`;

const modalContentStyles = ({ theme, height, contentPadding, white }) => css`
  animation: ${appearFromBottom} 0.5s;
  position: relative;
  background: ${white ? theme.colors.white : theme.colors.gray5};
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
  }

  ${contentPadding
    ? `padding: ${contentPadding}`
    : 'padding: 2rem 2rem 3rem 2rem;'};
  border-radius: 4px;
  border: solid 1px ${theme.colors.gray6};
  z-index: 999 !important;
  ${height && `height: ${height}`};
`;

const buttonCloseStyles = ({ theme, dark }) => css`
  animation: ${appearFromBottom} 0.5s;
  position: absolute;
  top: 3.313rem;
  right: 3.5rem;
  z-index: 999999;
  cursor: pointer;
  transition: 0.5s;

  svg {
    color: ${theme.colors.gray};

    ${'' /* border: solid 1px ${theme.colors.gray2};
    border-radius: 4px; */}
    transition: 0.5s;
  }

  &:hover {
    transition: 0.5s;

    border-radius: 50%;

    svg {
      transition: 0.5s;
      color: ${dark ? '#111' : '#fff'};
      opacity: 1;
    }
  }
`;

export const Modal = styled('div')(modalStyles);
export const ModalContainer = styled('div')(modalContainerStyles);
export const ModalContent = styled('div')(modalContentStyles);
export const ButtonClose = styled('div')(buttonCloseStyles);

import * as yup from 'yup';

export default function validations() {
  return yup.object().shape({
    name: yup.string().required('O nome é obrigatório'),
    email: yup
      .string()
      .email('Insira um e-mail válido')
      .required('O e-mail é obrigatório'),
    password: yup.string().required('A senha é obrigatória'),
    confirm_password: yup
      .string()
      .required('A confirmação de senha é obrigatória')
      .oneOf([yup.ref('password'), null], 'As senhas não coincidem')
      .nullable(),
  });
}

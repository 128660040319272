import styled from '@emotion/styled';
import { css } from '@emotion/react';
import * as U from '~/styles/utilities';

const clientsSectionStyles = () => css`
  padding: 100px 0;
`;

const clientsCompanyStyles = () => css`
  width: 150px;
  height: 90px;
  object-fit: contain;
  filter: grayscale(1);

  @media (max-width: 768px) {
    width: 125px;
  }
`;

const clientsCompanyTitleStyles = ({ theme }) => css`
  color: ${theme.colors.gray4};
  margin-bottom: 43px;
`;

const alignImagesStyles = ({ theme }) => css`
  width: 220px;
  height: 100px;
  display: flex;
  align-items: center;
`;

export const ClientsSection = styled('section')(clientsSectionStyles);
export const ClientsCompany = styled('img')(clientsCompanyStyles);
export const ClientsCompanyTitle = styled(U.TitleShapes)(
  clientsCompanyTitleStyles
);
export const AlignImages = styled('div')(alignImagesStyles);

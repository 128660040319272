import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Link } from 'react-router-dom';

import * as U from '~/styles/utilities';
import { appearFromBottom } from '~/styles/animations';

const wrapperStyles = () => css`
  position: relative;
  animation: ${appearFromBottom} 0.5s;
`;

const imageBackgroundStyles = ({ theme }) => css`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 1;
  transition: opacity 0.5s ease-out;
  padding: 0px 70px 50px 50px;

  width: 270px;
  object-fit: contain;

  @media (max-width: ${theme.breaks.medium}) {
    width: 200px;
    object-fit: cover;
  }
`;

const imageBackgroundHoverStyles = ({ theme, active }) => css`
  position: absolute;
  top: 0;
  left: 0;
  opacity: ${active ? 1 : 0};
  transition: opacity 0.5s ease-out;
  padding: 0px 70px 50px 50px;
  width: 270px;
  object-fit: contain;

  @media (max-width: ${theme.breaks.medium}) {
    width: 200px;
    object-fit: cover;
  }
`;

const cardStyles = ({ theme }) => css`
  position: relative;
  padding-top: 17px;

  @media (min-width: ${theme.breaks.large}) {
    margin: 0px 40px 50px 70px;
  }

  @media (max-width: ${theme.breaks.large}) {
    margin-bottom: 50px;
  }

  @media (max-width: ${theme.breaks.small}) {
    margin: 0 35px 50px 0;
  }
`;

const courseImgStyles = () => css`
  height: 27px;
  margin: 14px 0;
`;

const cardTextStyles = () => css`
  line-height: 26px;
  margin-bottom: 14px;
  height: 5rem;
`;

const cardInfoStyles = ({ theme, type }) => css`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  p,
  svg {
    color: ${(type === 'circle' && theme.colors.lightblue) ||
    (type === 'square' && theme.colors.orange) ||
    (type === 'triangle' && theme.colors.yellow) ||
    (type === 'diamond' && theme.colors.green) ||
    theme.colors.lightblue};
    font-size: ${theme.typography.sm};
  }
`;

const linkPurchaseStyles = ({ theme }) => css`
  color: ${theme.colors.white};
  font-size: ${theme.typography.xs};
  margin-bottom: 24px;

  &:hover {
    text-decoration: underline;
  }
`;

const titleStyles = ({ theme, type }) => css`
  font-size: ${theme.typography.xs};
  color: ${(type === 'circle' && theme.colors.lightblue) ||
  (type === 'square' && theme.colors.orange) ||
  (type === 'triangle' && theme.colors.yellow) ||
  (type === 'diamond' && theme.colors.green) ||
  theme.colors.lightblue};
  text-transform: uppercase;
  line-height: 19px;

  letter-spacing: 2.22px;
  font-weight: 500;
  margin-bottom: ${theme.spacing.s1};
  height: 1rem;
`;

const cardWrapperStyles = ({ theme }) => css``;

export const Wrapper = styled('div')(wrapperStyles);
export const ImageBackground = styled('img')(imageBackgroundStyles);
export const ImageBackgroundHover = styled('img')(imageBackgroundHoverStyles);
export const Card = styled('div')(cardStyles);
export const CardWrapper = styled('div')(cardWrapperStyles);
export const CourseImg = styled('img')(courseImgStyles);
export const CardText = styled(U.Text)(cardTextStyles);
export const CardInfo = styled('div')(cardInfoStyles);
export const LinkPurchase = styled(Link)(linkPurchaseStyles);
export const Title = styled('h1')(titleStyles);

import React, { createContext, useState, useContext } from 'react';

const StatesContext = createContext();

const StatesProvider = ({ children }) => {
  const [modalSignUp, setModalSignUp] = useState(false);
  const [modalLogin, setModalLogin] = useState(false);

  const [hasBillet, setHasBillet] = useState(() => {
    const storageBilletData = JSON.parse(localStorage.getItem('@billet'));
    if (storageBilletData) {
      return storageBilletData;
    }
    return {
      details: false,
      print: false,
      exists: false,
    };
  });

  const toogleSignUp = () => {
    setModalSignUp(true);
    setModalLogin(false);
  };

  const toogleLogin = () => {
    setModalLogin(true);
    setModalSignUp(false);
  };

  const onCloseSign = () => {
    setModalLogin(false);
  };

  const onCloseSignUp = () => {
    setModalSignUp(false);
  };

  return (
    <StatesContext.Provider
      value={{
        toogleSignUp,
        modalSignUp,
        toogleLogin,
        modalLogin,
        onCloseSign,
        onCloseSignUp,
        hasBillet,
        setHasBillet,
      }}
    >
      {children}
    </StatesContext.Provider>
  );
};

function useStateContext() {
  const context = useContext(StatesContext);

  if (!context) {
    throw new Error('useStateContext must be used within an StatesProvider');
  }
  return context;
}
export { StatesProvider, useStateContext };

import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { motion } from 'framer-motion';
import { Link } from 'react-scroll';

const wrapperStyles = ({ theme }) => css`
  max-width: 77rem;
  margin: 0 auto;

  @media (max-width: ${theme.breaks.large}) {
    padding: 0 2rem;
  }
`;

const wrapperRelativeStyles = () => css`
  position: relative;
`;

const containerLimitStyles = ({ theme }) => css`
  max-width: 77rem;
  margin: 0 auto;

  @media (max-width: ${theme.breaks.large}) {
    padding: 0 2rem;
  }

  @media (max-width: ${theme.breaks.small}) {
    padding: 0 1rem;
  }
`;

const fullSizeStyles = () => css`
  width: 100%;
`;

const titleShapeColorsStyles = ({ theme, type, noMargin }) => css`
  font-size: ${theme.typography.xs};
  color: ${(type === 'circle' && theme.colors.lightblue) ||
  (type === 'square' && theme.colors.orange) ||
  (type === 'triangle' && theme.colors.yellow) ||
  (type === 'diamond' && theme.colors.green) ||
  theme.colors.lightblue};

  letter-spacing: 2.22px;
  font-weight: 500;
  ${!noMargin &&
  css`
    margin-bottom: ${theme.spacing.s1};
  `}
`;

const titleBigStyles = ({ theme }) => css`
  font-size: ${theme.typography.xl4};
  color: ${theme.colors.white};

  @media (max-width: ${theme.breaks.large}) {
    font-size: ${theme.typography.xl2};
  }

  @media (max-width: ${theme.breaks.small}) {
    font-size: ${theme.typography.xl};
  }

  span {
    color: ${theme.colors.blue};
  }
`;

const mbmStyles = ({ theme }) => css`
  margin-bottom: ${theme.spacing.m};
`;

const textStyles = ({ theme, bold, white, black }) => css`
  font-size: ${theme.typography.sm};
  color: ${black ? theme.colors.background : theme.colors.white};
  opacity: ${white ? 1 : '60%'};
  line-height: 24px;
  font-weight: ${bold && '600'};
`;

const vh100Styles = () => css`
  height: 100vh;
`;

const pointerStyles = ({ theme }) => css`
  @media (min-width: ${theme.breaks.medium1}) {
    cursor: pointer;
  }
`;

const buttonStyles = ({ theme, full, fullMobile }) => css`
  background: transparent;
  font-family: ${theme.font.montserrat};
  font-size: ${theme.typography.xl2};
  border: 0;
  padding: 10px 30px;
  font-size: ${theme.typography.sm};
  border: solid 1px ${theme.colors.blue};
  border-radius: 50px;
  color: ${theme.colors.blue};

  ${full &&
  css`
    width: 100%;
  `}

  ${fullMobile &&
  css`
    @media (max-width: ${theme.breaks.large}) {
      width: 100%;
    }
  `}
`;

const buttonMoreStyles = () => css`
  border-radius: 12px;
  padding: 10px 54px;
`;

const mobileButtonStyles = ({ theme, white }) => css`
  ${white &&
  css`
    color: ${theme.colors.white};
    border-color: ${theme.colors.white};
  `}

  @media (min-width: ${theme.breaks.large}) {
    display: none;
  }

  @media (max-width: ${theme.breaks.large}) {
    display: block;
  }
`;

const circleIcon = ({ theme }) => css`
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border-radius: 50%;
  background: ${theme.colors.blue};
  opacity: 0.4;
`;

const squareIcon = ({ theme }) => css`
  width: 20px;
  height: 20px;
  margin-right: 10px;
  background: ${theme.colors.orange};
  opacity: 0.4;
`;

const triangleIcon = ({ theme }) => css`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15.3px 10px 0 10px;
  border-color: ${theme.colors.yellow} transparent transparent transparent;
  line-height: 0px;
  _border-color: ${theme.colors.yellow} #000000 #000000 #000000;
  _filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
  opacity: 0.4;
  margin-right: 10px;
`;

const diamondIcon = ({ theme }) => css`
  width: 18px;
  height: 18px;
  margin-right: 10px;
  background: ${theme.colors.green};
  opacity: 0.4;
  transform: rotate(45deg);
`;

const gapStyles = ({ size }) => css`
  margin-top: ${size}px;
  margin-bottom: ${size}px;
`;

const buttonBlueStyles = ({ theme, light, full }) => css`
  background: ${light ? theme.colors.lightblue3 : theme.colors.blue};
  font-family: ${theme.font.montserrat};
  font-size: ${theme.typography.md};
  font-weight: 500;
  border: 0;
  padding: 10px 20px;
  font-size: ${theme.typography.sm};
  border-radius: 50px;
  color: ${theme.colors.white};
  ${full && `width: 100%`};
`;

const titleSmallStyles = ({ theme, orange, yellow, green, blue }) => css`
  ${orange && `color: ${theme.colors.orange}`};
  ${yellow && `color: ${theme.colors.yellow}`};
  ${green && `color: ${theme.colors.green}`};
  ${blue && `color: ${theme.colors.lightblue2}`};
  font-size: ${theme.typography.md};
  font-weight: 500;
  display: flex;
  align-items: center;
`;

const cookiesBar = ({ theme }) => css`
  background: ${theme.colors.gray5};

  display: flex;
  position: fixed;

  padding: 15px 56px;
  bottom: 30px;

  max-width: 77rem;
  z-index: 99;

  border-radius: 4px 4px 0px 0px;

  p {
    font-size: ${theme.typography.xs};
    color: ${theme.colors.white};
    font-family: ${theme.font.montserrat};
    letter-spacing: 1.2px;
    line-height: 18px;
  }

  a {
    color: ${theme.colors.white};
    font-weight: bold;
  }

  a:hover {
    text-decoration: underline;
  }

  @media (max-width: ${theme.breaks.medium}) {
    flex-direction: column;

    padding: 15px 20px;
    bottom: 0;

    p {
      margin: 20px 0;
    }
  }
`;

const titleBoldStyles = ({ theme }) => css`
  color: ${theme.colors.white};
  font-weight: bold;
  line-height: 43px;

  @media (max-width: ${theme.breaks.small}) {
    font-size: 25px;
  }
`;

const perspectiveStyles = () => css`
  perspective: 1500px;
`;

const backgroundFooterStyles = ({ theme }) => css`
  position: relative;

  background: ${theme.colors.black1};
`;

export const WrapperContent = styled('div')(wrapperStyles);
export const Relative = styled('div')(wrapperRelativeStyles);
export const ContainerLimit = styled('div')(containerLimitStyles);
export const FullContainer = styled('div')(fullSizeStyles);
export const TitleShapes = styled('h1')(titleShapeColorsStyles);
export const TitleBold = styled('h1')(titleBoldStyles);
export const TitleBig = styled('h1')(titleBigStyles);
export const TitleBigBanner = styled(TitleBig)(mbmStyles);
export const Text = styled('p')(textStyles);
export const Main = styled('div')(vh100Styles);
export const ButtonHoverAnimate = styled(motion.div)(pointerStyles);
export const Button = styled(motion.button)(buttonStyles);
export const ButtonMore = styled(Button)(buttonMoreStyles);
export const MobileButton = styled(Button)(mobileButtonStyles);
export const CircleIcon = styled('div')(circleIcon);
export const SquareIcon = styled('div')(squareIcon);
export const TriangleIcon = styled('div')(triangleIcon);
export const DiamondIcon = styled('div')(diamondIcon);
export const Gap = styled('div')(gapStyles);
export const ButtonBlue = styled(motion.button)(buttonBlueStyles);
export const TitleSmall = styled('h1')(titleSmallStyles);
export const LinkScroll = styled(Link)();
export const CookiesBar = styled('div')(cookiesBar);
export const Perspective = styled('div')(perspectiveStyles);
export const BackgroundFooter = styled('div')(backgroundFooterStyles);

import { css } from '@emotion/react';
import styled from '@emotion/styled';

const baseStyles = ({ theme }) => css`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 24px;

  p,
  svg {
    font-weight: bold;
    color: ${theme.colors.blue};
    font-size: ${theme.typography.md};
  }

  p {
    margin-right: 20px;
  }

  svg {
    margin: 0;
  }
`;

const iconContainerStyles = ({ theme, rotate }) => css`
  border: solid 2px ${theme.colors.gray};
  width: 152px;
  height: 152px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  svg {
    margin: 0;
    ${rotate &&
    css`
      transform: rotate(-45deg);
    `};
  }
`;

export const SeeMore = styled('div')(baseStyles);
export const IconsContainer = styled('div')(iconContainerStyles);

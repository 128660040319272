import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Link } from 'react-scroll';
import Grid from '@material-ui/core/Grid';

import * as U from '~/styles/utilities';

const wrapperStyles = () => css`
  position: relative;
`;

const cardStyles = ({ theme }) => css`
  background: ${theme.colors.gray5};
  border: 1px solid ${theme.colors.gray6};
  border-radius: 4px;
  padding: 32px 24px;
`;

const titleCheckoutStyles = ({ theme, size }) => css`
  color: ${theme.colors.white};
  font-size: ${size ? `${size}px` : '40px'};
  font-weight: bold;
  line-height: 43px;
  margin-bottom: 24px;
`;

const infoStyles = ({ theme, noBorder }) => css`
  color: ${theme.colors.white};
  font-size: ${theme.typography.md};
  font-weight: 500;
  line-height: 24px;
  text-transform: uppercase;

  span {
    color: ${theme.colors.lightblue};
  }

  ${!noBorder &&
  `border-bottom: 2px solid ${theme.colors.gray6}; padding-bottom: 24px;`};
`;

const checkoutTextStyles = ({ theme }) => css`
  color: ${theme.colors.white};
  opacity: 60%;
  font-size: ${theme.typography.xs};
  font-weight: 500;
  line-height: 24px;
  margin: 27px 0 32px;
`;

const priceStyles = ({ theme, white }) => css`
  font-size: 16px;
  ${white && `color: ${theme.colors.white}`}
`;

const cardInfoStyles = ({ theme }) => css`
  display: flex;
  margin-top: 24px;

  font-size: ${theme.typography.m};
  p,
  svg {
    color: ${theme.colors.lightblue};
  }

  p {
    margin-left: 24px;
  }
`;

const priceTotalStyles = ({ theme }) => css`
  color: ${theme.colors.white};

  font-weight: bold;
  line-height: 43px;
`;

const continueBuyingStyles = ({ theme }) => css`
  @media (min-width: ${theme.breaks.medium1}) {
    cursor: pointer;
  } ;
`;

const coursesContainerStyles = () => css`
  margin-bottom: 70px;
`;

const totalSectionStyles = ({ theme }) => css`
  border-top: 2px solid ${theme.colors.gray6};
  border-bottom: 2px solid ${theme.colors.gray6};
  padding: 24px 0;
  margin-bottom: 30px;
`;

const totalStyles = ({ theme }) => css`
  color: ${theme.colors.white};
  font-size: 24px;
  font-weight: bold;
  line-height: 43px;
`;

const buttonStyles = ({ full, padding }) => css`
  border-radius: 4px;
  padding: ${padding ? `${padding}` : `12px 57px`};
  ${full && `width: 100%;`}
`;

const titlePaymentStyles = ({ theme, active }) => css`
  color: ${theme.colors.white};
  background: ${active
    ? `${theme.colors.gray5}`
    : `${theme.colors.background}`};

  @media (max-width: 600px) {
    background: ${active
      ? `${theme.colors.background}`
      : `${theme.colors.gray5}`};
  }

  transition: background 0.5s ease-out;

  font-size: ${theme.typography.md};
  line-height: 32px;
  font-weight: 500;
  display: flex;
  align-items: center;
  @media (min-width: ${theme.breaks.medium1}) {
    cursor: pointer;
  }
  padding: 20px 35px;

  svg {
    margin-right: 12px;
  }
`;

const choosePaymentSectionStyles = () => css`
  margin: 0 0 20px 0;
`;

const wrapperBannerStyles = ({ theme }) => css`
  min-height: 530px;

  @media (min-width: ${theme.breaks.large}) {
    margin-top: 200px;
  }

  @media (max-width: ${theme.breaks.medium}) {
    min-height: 90vh;
  }

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 100px;
`;

const gridPurchaseItemsStyles = ({ theme }) => css`
  @media (max-width: ${theme.breaks.medium}) {
    flex-direction: column;
  }
`;

const gridAlign = ({ theme }) => css`
  text-align: end;
  @media (max-width: ${theme.breaks.medium}) {
    text-align: left;
  }
`;

export const Wrapper = styled('div')(wrapperStyles);
export const TitleCheckout = styled('h1')(titleCheckoutStyles);

export const InfoText = styled('h1')(infoStyles);
export const CheckoutText = styled('h1')(checkoutTextStyles);

export const Price = styled(U.TitleShapes)(priceStyles);
export const PriceTotal = styled('h1')(priceTotalStyles);

export const CardInfo = styled('div')(cardInfoStyles);
export const ContinueBuying = styled(Link)(continueBuyingStyles);

export const Card = styled('div')(cardStyles);
export const CoursesContainer = styled('div')(coursesContainerStyles);
export const TotalSection = styled('section')(totalSectionStyles);
export const Total = styled('h1')(totalStyles);
export const Button = styled(U.ButtonBlue)(buttonStyles);
export const TitlePayment = styled('h1')(titlePaymentStyles);
export const ChoosePaymentSection = styled('section')(
  choosePaymentSectionStyles
);

export const WrapperBanner = styled('div')(wrapperBannerStyles);
export const GridPurchaseItems = styled(Grid)(gridPurchaseItemsStyles);
export const GridAlign = styled(Grid)(gridAlign);

import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import * as U from '~/styles/utilities';

const titleStyles = ({ theme }) => css`
  font-size: ${theme.typography.xl2};
  color: ${theme.colors.lightblue};
  height: 80px;
  font-weight: bold;
  margin-bottom: ${theme.spacing.l};
`;

const textStyles = ({ theme, blue }) => css`
  font-size: ${theme.typography.m};
  ${blue ? `color: ${theme.colors.lightblue}` : 'color: #959899;'};
  line-height: 32px;
  font-weight: 500;
`;

const textPlaceStyles = ({ theme }) => css`
  font-size: ${theme.typography.m};
  color: ${theme.colors.white};
  line-height: 32px;
  font-weight: 500;
`;

const cardStyles = ({ theme }) => css`
  background: ${theme.colors.gray5};
  border: 1px solid ${theme.colors.gray6};
  border-radius: 4px;
  opacity: 1;
  margin: 0 8px;
  position: relative;

  @media (max-width: ${theme.breaks.medium1}) {
    margin: 0;
  }
`;

const cardContentStyles = () => css`
  padding: 24px;

  section {
    height: 50px;
  }
`;

const buttonStyles = () => css`
  width: 100%;
  margin: 20px 0;
`;

const courseImgStyles = () => css`
  height: 27px;
  margin-bottom: 14px;
`;

const moreInfoStyles = ({ theme }) => css`
  color: ${theme.colors.lightblue};
  font-size: ${theme.typography.xs};

  &:hover {
    text-decoration: underline;
  }
`;

export const Title = styled('h1')(titleStyles);
export const Text = styled('p')(textStyles);
export const TextPlace = styled('p')(textPlaceStyles);
export const Card = styled('div')(cardStyles);
export const CardContent = styled('div')(cardContentStyles);
export const CardButton = styled(U.ButtonBlue)(buttonStyles);
export const CourseImg = styled('img')(courseImgStyles);
export const MoreInfo = styled(Link)(moreInfoStyles);

import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';

import * as F from '~/styles/form';
import * as U from '~/styles/utilities';
import * as S from '../styled';
import { useStateContext } from '~/hooks/context/modules/StatesContext';
import { useUserContext } from '~/hooks/context/modules/UserContext';
import api from '~/services/api';
import Loading from '~/components/Loading';
import validations from './validations';

import logo from '~/assets/img/logo.png';

const SignIn = () => {
  const { toogleSignUp, toogleLogin, onCloseSign } = useStateContext();
  const { setIsLogged, signIn, loading } = useUserContext();

  const handleSubmitForm = (data) => {
    signIn(data);
  };

  // form

  const initialValues = {
    username: '',
    password: '',
  };

  return (
    <>
      <Grid container justify="space-between" alignItems="center">
        <Grid item>
          <S.TitleModal>Faça login</S.TitleModal>
        </Grid>
        <Grid item>
          <S.LogoModal src={logo} alt="logo" />
        </Grid>
      </Grid>
      <U.Gap size={20} />
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmitForm}
        validationSchema={validations()}
      >
        {({
          handleSubmit,
          handleBlur,
          handleChange,
          values,
          errors,
          touched,
          setFieldValue,
        }) => (
          <>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <F.Label>E-mail</F.Label>
                  <F.Input
                    type="email"
                    placeholder="Digite seu e-mail"
                    name="username"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.username}
                  />
                  {errors.username && touched.username ? (
                    <F.Hint error={!!errors.username && touched.username}>
                      {errors.username}
                    </F.Hint>
                  ) : null}
                </Grid>
                <Grid item xs={12}>
                  <F.Label>Senha</F.Label>
                  <F.Input
                    type="password"
                    placeholder="Digite sua senha"
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                  />
                  {errors.password && touched.password ? (
                    <F.Hint error={!!errors.password && touched.password}>
                      {errors.password}
                    </F.Hint>
                  ) : null}
                </Grid>

                <Grid container item>
                  <S.InfoModal>Esqueceu sua senha?</S.InfoModal>

                  <Link to="/esqueci-a-senha/" onClick={() => onCloseSign()}>
                    <S.InfoModal>
                      <span>Recuperar Senha</span>
                    </S.InfoModal>
                  </Link>
                </Grid>

                <Grid
                  item
                  xs={12}
                  container
                  justify="flex-end"
                  style={{ margin: '38px 0 30px 0px' }}
                >
                  <U.ButtonBlue
                    // whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    full
                    type="submit"
                    className="flex-center"
                  >
                    {loading ? <Loading /> : 'ACESSAR'}
                  </U.ButtonBlue>
                </Grid>

                {/* <Grid container justify="center">
                  <S.InfoModal>Não tem uma conta? </S.InfoModal>
                  <S.InfoModal onClick={() => toogleSignUp()}>
                    <span>Crie uma conta</span>
                  </S.InfoModal>
                </Grid> */}
              </Grid>
            </form>
          </>
        )}
      </Formik>
    </>
  );
};

export default SignIn;

import styled from '@emotion/styled';
import { css } from '@emotion/react';

const containerStyles = ({ theme }) => css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;

  @media (max-width: ${theme.breaks.medium1}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const wrapperFilterStyles = ({ theme }) => css`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  h1 {
    &:not(:last-of-type) {
      margin-right: 37px;
    }
  }

  div,
  h1 {
    @media (min-width: ${theme.breaks.medium1}) {
      cursor: pointer;
    }
  }

  @media (max-width: ${theme.breaks.medium1}) {
    h1 {
      width: 100%;
      margin: 10px 0;
    }
  }
`;

const textFilterStyles = ({ theme }) => css`
  font-size: ${theme.typography.xs};
  color: ${theme.colors.white};
  font-weight: 500;
  margin-right: 48px;
  opacity: 60%;

  @media (max-width: ${theme.breaks.medium1}) {
    width: 100%;
    margin: 10px 0;
  }
`;

export const Container = styled('div')(containerStyles);
export const WrapperFilters = styled('div')(wrapperFilterStyles);
export const TextFilter = styled('div')(textFilterStyles);

import React, { useState, useRef } from 'react';

import PropTypes from 'prop-types';

import Header from './Header';
import Footer from './Footer';
import Menu from './Menu';

import useOutsideClick from '~/hooks/useOutsideClick';

import * as U from '~/styles/utilities';
import * as S from './styled';

const Layout = ({ children, noBanner, noHeader, noFooter, white }) => {
  const [open, setOpen] = useState(false);

  const node = useRef();

  useOutsideClick(node, () => {
    if (open) setOpen(false);
  });

  return (
    <S.Wrapper open={open}>
      <S.MenuContainer open={open}>
        <div ref={node}>
          <Menu open={open} noBanner={noBanner} white={white} />
        </div>
      </S.MenuContainer>

      {!noHeader && (
        <U.WrapperContent>
          <Header open={open} setOpen={setOpen} />
        </U.WrapperContent>
      )}
      <S.Container>{children}</S.Container>

      {!noFooter && <Footer />}
    </S.Wrapper>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
  noBanner: PropTypes.bool,
};

export default Layout;

import styled from '@emotion/styled';
import { css, keyframes } from '@emotion/react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

import * as U from '~/styles/utilities';

const menuStyles = ({ theme, noBanner, open }) => css`
  position: ${noBanner ? `relative;` : `absolute`};
  width: 100%;
  z-index: 999;

  @media (max-width: ${theme.breaks.large}) {
    width: 100%;
    max-width: 20rem;
    position: fixed;
    height: 100%;
    overflow-y: auto;
    margin-top: 0;
    transform: ${open ? 'translateX(0)' : 'translateX(-100%)'};
    transition: transform 0.3s ease-in-out;
    background: #252c2d;
  }

  @media (max-width: 350px) {
    max-width: 24rem;
  }
`;

const containerStyles = ({ theme }) => css`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 77rem;
  margin: 0 auto;
  padding: 30px 0 0 0;

  @media (max-width: ${theme.breaks.large}) {
    padding: 2.4rem 1.5rem;
    flex-direction: column;
    height: 100%;
  }
`;

const logoStyles = () => css`
  height: 60px;
`;

const wrapperStyles = ({ theme }) => css`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;

  @media (max-width: ${theme.breaks.large}) {
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const listStyles = ({ theme }) => css`
  display: flex;
  align-items: center;

  @media (max-width: ${theme.breaks.large}) {
    flex-direction: column;
    align-items: flex-start;
    line-height: 40px;
    margin-top: 40px;
  }

  @media (max-width: ${theme.breaks.large}) {
    a {
      padding-left: 10px;
    }
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }

`;

const itemStyles = ({ theme, active, dropdown }) => css`
  position: relative;
  color: ${theme.colors.white};
  list-style: none;
  font-size: ${theme.typography.sm};
  letter-spacing: 1.4px;
  font-weight: 500;
  padding: 14px 0;

  &:not(:last-child) {
    margin-right: ${theme.spacing.xl};
  }

  @media (min-width: ${theme.breaks.large}) {
    ul,
    li {
      display: none;
      line-height: 34px;
    }

    ${dropdown &&
    css`
      &:hover {
        ul,
        li {
          display: block;
          animation: ${fadeIn} 0.5s;
        }
        ul {
          position: absolute;
          display: flex;
          flex-direction: column;

          padding: 25px 25px 30px;

          margin-top: 5px;
          top: 20px;
          left: -5px;
          width: 205px;

          a {
            font-size: ${theme.typography.xs};
            color: ${theme.colors.white};
            font-weight: 200;

            @media (min-width: ${theme.breaks.large}) {
              &:not(:last-of-type) {
                margin-bottom: 20px;
              }
            }
          }
          a:hover {
            color: ${theme.colors.lightblue};
          }
        }
      }
    `}
  }
  @media (min-width: ${theme.breaks.medium1}) {
    cursor: pointer;
  }

  @media (max-width: ${theme.breaks.large}) {
    ul,
    li {
      display: block;
      animation: ${fadeIn} 0.5s;
      line-height: 34px;
    }
    ul {
      a {
        font-size: ${theme.typography.xs};
        color: ${theme.colors.white};
        font-weight: 200;

        @media (min-width: ${theme.breaks.large}) {
          &:not(:last-of-type) {
            margin-bottom: 20px;
          }
        }
      }
      a:hover {
        color: ${theme.colors.lightblue};
      }
    }
  }
  ${
    '' /* border-bottom: 3px solid ${active ? `${theme.colors.white}` : 'transparent'}; */
  }

  @media (max-width: ${theme.breaks.large}) {
    ${
      '' /* border-left: 3px solid ${active ? `${theme.colors.white}` : 'transparent'}; */
    }
    border-bottom: none;
  }
`;

const buttonHeaderStyles = ({ theme, white }) => css`
  ${white &&
  css`
    color: ${theme.colors.white};
    border-color: ${theme.colors.white};
  `}

  @media (max-width: ${theme.breaks.large}) {
    display: none;
  }
`;

const buttonCartStyles = ({ theme, white, total }) => css`
  ${white &&
  css`
    color: ${theme.colors.white};
    border-color: ${theme.colors.white};
  `}
  height: 43px;
  width: 43px;
  padding: 0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  margin-right: 10px;
  position: relative;

  &::after {
    content: ${total && `'${total}'`};
    position: absolute;
    height: 17px;
    top: -5px;
    right: -4px;
    width: 17px;
    font-size: 11px;
    font-weight: 700;
    background: #f6041c;
    border-radius: 100%;
    color: #fff;
  }
`;

const wrapperMobileStyles = ({ theme }) => css`
  @media (max-width: ${theme.breaks.large}) {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }
`;

const paddingLeft10 = () => css`
  padding-left: 10px;
`;

const titleModalStyles = ({ theme }) => css`
  font-size: ${theme.typography.xl2};
  color: ${theme.colors.lightblue};
  line-height: 25px;
`;

const infoModalStyles = ({ theme }) => css`
  font-size: ${theme.typography.xs};
  color: ${theme.colors.lightblue};
  line-height: 19px;
  font-weight: 200;

  a {
    color: ${theme.colors.lightblue};
  }

  a:hover {
    text-decoration: underline;
  }

  span {
    font-weight: bold;
    cursor: pointer;
    margin-left: 5px;
  }
`;

const logoModalStyles = ({ theme }) => css`
  height: 50px;

  @media (max-width: ${theme.breaks.medium}) {
    display: none;
  }
`;

export const Menu = styled('div')(menuStyles);
export const Container = styled('div')(containerStyles);
export const Logo = styled('img')(logoStyles);
export const Wrapper = styled('div')(wrapperStyles);
export const List = styled('ul')(listStyles);
export const Item = styled(Link)(itemStyles);
export const ItemD = styled('div')(itemStyles, paddingLeft10);
export const ButtonHeader = styled(U.Button)(buttonHeaderStyles);
export const ButtonCart = styled(ButtonHeader)(buttonCartStyles);
export const ButtonCartMobile = styled(U.MobileButton)(buttonCartStyles);
export const WrapperMobile = styled('div')(wrapperMobileStyles);
export const ItemDropdown = styled(motion.li)();

export const TitleModal = styled('h1')(titleModalStyles);
export const InfoModal = styled('p')(infoModalStyles);
export const LogoModal = styled('img')(logoModalStyles);

import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from 'react';

import Toast from '~/components/Alert';

const CheckoutContext = createContext();

const CheckoutProvider = ({ children }) => {
  const [coupon, setCoupon] = useState('');

  const [cartCourses, setCartCourses] = useState(() => {
    const storageCourses = JSON.parse(localStorage.getItem('@courses'));
    if (storageCourses) {
      return storageCourses;
    }
    return [];
  });

  const [total, setTotal] = useState();

  useEffect(() => {
    if (cartCourses.length !== 0 && cartCourses !== null) {
      const totalPrice = cartCourses
        .map((item) => {
          if (item.type === 'bootcamp') {
            return Number(item.price);
          }
          if (item.special_price !== null) {
            return Number(item.special_price);
          }
          return Number(item.price);
        })
        .reduce((prev, curr) => Number(prev) + Number(curr));
      return setTotal(totalPrice);
    }
    if (cartCourses.length === 0) {
      setTotal(0);
    }
    return '';
  }, [cartCourses]);

  // add

  const addToCart = useCallback(
    (courseData) => {
      if (courseData !== null) {
        const verify = cartCourses.some(
          (item) => item.id === courseData.id && item.type === courseData.type
        );
        if (!verify) {
          setCartCourses([...cartCourses, courseData]);
          localStorage.setItem(
            '@courses',
            JSON.stringify([...cartCourses, courseData])
          );
        } else {
          Toast.warning(
            `O ${
              courseData.type === 'online' ? 'curso' : 'bootcamp'
            } já está no carrinho`
          );
        }
      } else {
        setCartCourses([]);
      }
    },
    [cartCourses]
  );

  // remove

  const removeToCart = (itemId) => {
    const newItems = cartCourses.filter((item) => item.id !== itemId);

    setCartCourses(newItems);

    localStorage.setItem('@courses', JSON.stringify(newItems));
  };
  const cleanCart = () => {
    setCartCourses([]);

    localStorage.removeItem('@courses');
  };

  return (
    <CheckoutContext.Provider
      value={{
        cartCourses,
        setCartCourses,
        addToCart,
        total,
        removeToCart,
        coupon,
        setCoupon,
        cleanCart,
      }}
    >
      {children}
    </CheckoutContext.Provider>
  );
};

function useCheckoutContext() {
  const context = useContext(CheckoutContext);

  if (!context) {
    throw new Error(
      'useCheckoutContext must be used within an CheckoutProvider'
    );
  }
  return context;
}
export { CheckoutProvider, useCheckoutContext };

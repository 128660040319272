import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';

import { GrInstagram, GrLinkedin } from 'react-icons/gr';
import { animateScroll as scroll } from 'react-scroll';
import { MdKeyboardArrowUp } from 'react-icons/md';

import Clients from './components/Clients';
import * as S from './styled';
import * as U from '~/styles/utilities';
import logoWhite from '~/assets/img/white-logo.svg';

const Footer = ({ withoutAsset, withoutCompanies }) => (
  <>
    <U.WrapperContent>{!withoutCompanies && <Clients />}</U.WrapperContent>

    <S.Footer>
      {!withoutAsset && <S.Background />}
      <U.WrapperContent>
        <S.Container>
          <Grid container justify="flex-start" spacing={4}>
            <Grid xs={12} sm={6} style={{ zIndex: 1 }}>
              <S.LogoContainer>
                <Link to="/">
                  <img src={logoWhite} alt="logo" />
                </Link>
                <S.AlignIcons>
                  <U.ButtonHoverAnimate
                    whileHover={{ scale: 1.3 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    <a
                      href="https://www.instagram.com/empowerdata/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <GrInstagram size={20} color="#fff" />
                    </a>
                  </U.ButtonHoverAnimate>

                  <U.ButtonHoverAnimate
                    whileHover={{ scale: 1.3 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    <a
                      href="https://www.linkedin.com/company/empdata/?originalSubdomain=br"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <GrLinkedin size={20} color="#fff" />
                    </a>
                  </U.ButtonHoverAnimate>
                </S.AlignIcons>
              </S.LogoContainer>
            </Grid>

            <Grid
              container
              item
              xs={12}
              sm={6}
              spacing={3}
              style={{ zIndex: 1 }}
            >
              <Grid item xs={12} sm={8}>
                <U.TitleShapes>NÓS SOMOS </U.TitleShapes>
                <U.Text>
                  A Empowerdata é uma empresa de treinamento e consultoria na
                  área de análise de dados, Business Intelligence & Analytics.
                </U.Text>
              </Grid>
              <Grid item xs={12}>
                <U.TitleShapes>CONTATO</U.TitleShapes>
                <U.Text>plataforma@empowerdata.com.br.</U.Text>
                <U.Text>22 98104.6094</U.Text>
              </Grid>
              <Grid item xs={12}>
                <U.Text>Empowerdata 2021© | CNPJ: 27.955.892/0001-30</U.Text>
              </Grid>
            </Grid>
          </Grid>
        </S.Container>
      </U.WrapperContent>
      <S.IconTopWrapper
        onClick={() => scroll.scrollToTop()}
        whileHover={{ scale: 1.15 }}
        whileTap={{ scale: 0.95 }}
      >
        <MdKeyboardArrowUp size={25} color="#fff" />
      </S.IconTopWrapper>
    </S.Footer>
  </>
);

export default Footer;

import styled from '@emotion/styled';
import { css } from '@emotion/react';

const wrapperStyles = () => css`
  position: relative;
  min-height: 100vh;
`;

const menuContainerStyles = ({ theme, open }) => css`
  @media (max-width: ${theme.breaks.large}) {
    ${open
      ? css`
          background: #0000005c;
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 999;
        `
      : ''};
  }
`;

const containerStyles = () => css`
  min-height: calc(100vh - 109px);
`;

export const Wrapper = styled('div')(wrapperStyles);
export const MenuContainer = styled('div')(menuContainerStyles);
export const Container = styled('div')(containerStyles);

import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { FaShoppingCart } from 'react-icons/fa';
import { createBrowserHistory } from 'history';
import { Link } from 'react-router-dom';
import Modal from '~/components/Modal';
import SignIn from './SignIn';
import SignUp from './SignUp';
import { useCheckoutContext } from '~/hooks/context/modules/CheckoutContext';

import whiteLogo from '~/assets/img/whitelogo.png';
import logo from '~/assets/img/logo.png';
import { useStateContext } from '~/hooks/context/modules/StatesContext';
import { useUserContext } from '~/hooks/context/modules/UserContext';
import useOutsideClick from '~/hooks/useOutsideClick';

import * as S from './styled';
import * as U from '~/styles/utilities';

const Menu = ({ open, noBanner, white }) => {
  const [isShow, setIsShow] = useState(false);

  function showDropdown() {
    setIsShow(!isShow);
  }

  const { cartCourses } = useCheckoutContext();
  const { token, isLogged } = useUserContext();

  const {
    toogleSignUp,
    modalSignUp,
    toogleLogin,
    modalLogin,
    onCloseSign,
    onCloseSignUp,
  } = useStateContext();

  const node = useRef();

  useOutsideClick(node, () => {
    if (isShow) setIsShow(false);
  });

  const refModalLogin = useRef();

  useOutsideClick(refModalLogin, () => {
    if (modalLogin) {
      toogleLogin();
    }
  });

  const refModalSignUp = useRef();

  useOutsideClick(refModalSignUp, () => {
    if (modalSignUp) {
      toogleSignUp();
    }
  });

  // const toogleDashboard = () => {
  //   window.location.href = `https://app.empowerdata.com.br/entrar/?token=${token}`;
  // };

  const toogleDashboard = () => {
    window.location.href = `https://alunos.empowerdata.com.br/`;
  };

  const currentRoute = createBrowserHistory().location.pathname;

  return (
    <>
      <S.Menu open={open} noBanner={noBanner}>
        <S.Container>
          <S.Wrapper>
            <Link to="/">
              <S.Logo src={white ? whiteLogo : logo} alt="logo" />
            </Link>

            <S.List>
              <S.ItemD onMouseEnter={showDropdown} dropdown>
                Treinamentos
                <ul>
                  <S.ItemDropdown whileHover={{ scale: 1.05 }}>
                    <Link
                      to={{
                        pathname: '/',
                        hash: '#otc',
                      }}
                    >
                      Online
                    </Link>
                  </S.ItemDropdown>
                  <S.ItemDropdown whileHover={{ scale: 1.05 }}>
                    <Link to="/bootcamp">Presenciais</Link>
                  </S.ItemDropdown>
                  <S.ItemDropdown whileHover={{ scale: 1.05 }}>
                    <Link to="/incompany">Incompany</Link>
                  </S.ItemDropdown>
                </ul>
              </S.ItemD>

              <S.Item
                to="/consultoria"
                active={currentRoute === '/consultoria'}
              >
                Consultoria
              </S.Item>

              {/* <S.Item to="/#blog" active={currentRoute === '/#blog'}>
                Blog
              </S.Item> */}

              {/* {!isLogged && (
                <S.Item onClick={() => toogleSignUp()}>Cadastre-se</S.Item>
              )} */}

              {/* <S.Item>
                <S.ButtonHeader
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  white={white}
                  onClick={isLogged ? toogleDashboard : () => toogleLogin()}
                >
                  {isLogged ? 'Área do Aluno' : 'Login'}
                </S.ButtonHeader>
              </S.Item> */}

              <S.Item>
                <S.ButtonHeader
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  white={white}
                  onClick={toogleDashboard}
                >
                  Área do Aluno
                </S.ButtonHeader>
              </S.Item>

              {/* CARRINHO */}
              {/* <S.Item to="/carrinho">
                <S.ButtonCart
                  whileTap={{ scale: 0.85 }}
                  white={white}
                  total={cartCourses.length}
                >
                  <FaShoppingCart />
                </S.ButtonCart>
              </S.Item> */}
            </S.List>
          </S.Wrapper>

          <S.WrapperMobile>
            {/* <U.MobileButton
              white={white}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={isLogged ? toogleDashboard : () => toogleLogin()}
            >
              {isLogged ? 'Área do Aluno' : 'Login'}
            </U.MobileButton> */}
            <U.MobileButton
              white={white}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={toogleDashboard}
            >
              Área do Aluno
            </U.MobileButton>
            {/* CARRINHO */}
            {/* <Link to="/carrinho">
              <S.ButtonCartMobile
                white={white}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                total={cartCourses.length}
              >
                <FaShoppingCart />
              </S.ButtonCartMobile>
            </Link> */}
          </S.WrapperMobile>
        </S.Container>
      </S.Menu>

      {/* modal login */}

      {modalLogin && (
        <Modal onClose={() => onCloseSign()} hasButton>
          <div ref={refModalLogin} style={{ marginTop: 15 }}>
            <SignIn />
          </div>
        </Modal>
      )}

      {/* modal signup */}

      {modalSignUp && (
        <Modal fullresp onClose={() => onCloseSignUp()} hasButton>
          <div ref={refModalSignUp} style={{ marginTop: 15 }}>
            <SignUp />
          </div>
        </Modal>
      )}
    </>
  );
};

Menu.propTypes = {
  open: PropTypes.bool,
  noBanner: PropTypes.bool,
};

export default Menu;

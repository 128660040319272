import React from 'react';

import { Text, Gap } from '~/styles/utilities';
import powerbi from '~/assets/img/curso-FundBI.png';
import dashboardDesign from '~/assets/img/curso-DesignDash.png';
import daxFundamentals from '~/assets/img/curso-FundDax.png';
import daxDominate from '~/assets/img/curso-DominandoDAX02.jpeg';
import powerQuery from '~/assets/img/powerquerybkg.png';
import theme from '~/styles/theme';

import vinicius from '~/assets/img/vinicius.jpg';
import lorennzo from '~/assets/img/lorennzo.jpeg';

export const matters = [
  {
    id: '1',
    title: 'Fundamentos de Power BI',
    subtitle: 'NÍVEL ANALISTA',
    color: theme.colors.lightblue,
    description: (
      <>
        <Text white>
          Você vai aprender todos os passos que precisa para criar seus próprios
          projetos do zero, desde a aquisição e tratamento dos dados até a
          apresentação de um dashboard completo.
        </Text>
        <Gap size={10} />
        <Text white>
          Ao final do curso, você será desafiado a construir uma solução para
          analisar as vendas uma empresa.
        </Text>
      </>
    ),
    image: powerbi,
    teachers: [
      {
        name: 'Vinícius Rocha',
        photo: vinicius,
        profession: 'Lead data scientist',
        linkedin: 'https://www.linkedin.com/in/viniljf',
      },
      {
        name: 'Lorennzo Rodrigues',
        photo: lorennzo,
        profession: 'BI Specialist',
        linkedin: 'https://www.linkedin.com/in/lorennzo-rodrigues',
      },
    ],
  },
  {
    id: '2',
    title: 'Fundamentos de DAX',
    subtitle: 'NÍVEL ESPECIALISTA',
    color: theme.colors.green2,
    description: (
      <Text white>
        Desbloqueie um novo patamar das suas análises utilizando a linguagem
        DAX, explorando além dos limites do Power BI tradicional. Este curso
        aborda desde os conceitos mais básicos até análises de complexidade
        intermediária. Esse é o importante passo que vai te qualificar como um
        especialista dentro do Power BI.
      </Text>
    ),
    image: daxFundamentals,
    teachers: [
      {
        name: 'Vinícius Rocha',
        photo: vinicius,
        profession: 'Lead data scientist',
        linkedin: 'https://www.linkedin.com/in/viniljf',
      },
      {
        name: 'Lorennzo Rodrigues',
        photo: lorennzo,
        profession: 'BI Specialist',
        linkedin: 'https://www.linkedin.com/in/lorennzo-rodrigues',
      },
    ],
  },
  {
    id: '3',
    title: 'Design de dashboards',
    subtitle: 'NÍVEL COMUNICADOR',
    color: theme.colors.green,
    description: (
      <>
        <Text white>
          Você vai aprender a escolher os gráficos certos de acordo com o tipo
          de projeto e aplicar as boas práticas em cada um deles. Aprenda a
          contar histórias com seus dados e como apresentá-los diante de toda a
          empresa.
        </Text>
        <Gap size={10} />
        <Text white>
          Ganhe visibilidade e se destaque da concorrência criando seus próprios
          templates com design profissional.
        </Text>
      </>
    ),
    image: dashboardDesign,
    teachers: [
      {
        name: 'Vinícius Rocha',
        photo: vinicius,
        profession: 'Lead data scientist',
        linkedin: 'https://www.linkedin.com/in/viniljf',
      },
      {
        name: 'Lorennzo Rodrigues',
        photo: lorennzo,
        profession: 'BI Specialist',
        linkedin: 'https://www.linkedin.com/in/lorennzo-rodrigues',
      },
    ],
  },

  {
    id: '4',
    title: 'Fundamentos de Power Query',
    subtitle: 'NÍVEL ESPECIALISTA',
    color: theme.colors.green2,
    description: (
      <Text white>
        Descubra, conecte e combine suas fontes de dados para atender as
        necessidades de análise do seu projeto de BI. Este curso aborda diversos
        recursos existentes dentro do Power BI para construção de um modelo de
        dados estruturado. Sem necessitar de nenhum tipo de código, você vai
        aprender a realizar todo processo de ETL dos seus projetos.
      </Text>
    ),
    image: powerQuery,
    teachers: [
      {
        name: 'Vinícius Rocha',
        photo: vinicius,
        profession: 'Lead data scientist',
        linkedin: 'https://www.linkedin.com/in/viniljf',
      },
      {
        name: 'Lorennzo Rodrigues',
        photo: lorennzo,
        profession: 'BI Specialist',
        linkedin: 'https://www.linkedin.com/in/lorennzo-rodrigues',
      },
    ],
  },
  {
    id: '5',
    title: 'Dominando DAX',
    subtitle: 'NÍVEL ESTRATEGISTA',
    color: theme.colors.yellow,
    description: (
      <Text white>
        Vamos explorar um mundo ainda desconhecido para a maioria dos
        desenvolvedores Power BI. Você vai dominar conceitos avançados da
        linguagem DAX, saber como extrair o poder máximo das suas análises e se
        tornar uma referência em Power BI. Com esses novos conhecimentos, você
        estará apto a resolver problemas complexos relacionados a qualquer área
        de atuação.
      </Text>
    ),
    image: daxDominate,
    teachers: [
      {
        name: 'Vinícius Rocha',
        photo: vinicius,
        profession: 'Lead data scientist',
        linkedin: 'https://www.linkedin.com/in/viniljf',
      },
      {
        name: 'Lorennzo Rodrigues',
        photo: lorennzo,
        profession: 'BI Specialist',
        linkedin: 'https://www.linkedin.com/in/lorennzo-rodrigues',
      },
    ],
  },
];

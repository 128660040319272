import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Link } from 'react-scroll';
import { pulsate } from '~/styles/animations';

import chart from '~/assets/img/chart.png';

const wrapperStyles = () => css`
  position: relative;
`;

const wrapperBannerStyles = ({ theme }) => css`
  min-height: 480px;

  @media (min-width: ${theme.breaks.large}) {
    margin-top: 200px;
  }

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 100px;
`;

const contentBannerStyles = ({ theme }) => css`
  display: flex;
  align-items: flex-start;
  text-align: left;
`;

const marginTextBannerStyles = ({ theme }) => css`
  @media (min-width: ${theme.breaks.large}) {
    margin-top: 80px;
  }
`;

const imageBannerStyles = ({ theme }) => css`
  width: 95%;
  object-fit: contain;

  @media (max-width: ${theme.breaks.large}) {
    min-width: auto;
    margin-top: -88px;
  }
`;

const moreKnowledgeStyles = ({ theme }) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px;
  @media (min-width: ${theme.breaks.medium1}) {
    cursor: pointer;
  }

  span {
    position: relative;
    background: #234950;
    display: flex;
    margin-left: 15px;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    border-radius: 50%;
  }

  span:before {
    content: '';
    border: 13px solid #244950;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    position: absolute;
    left: -13px;
    bottom: -13px;
    animation: ${pulsate} 1.6s ease-out;
    animation-iteration-count: infinite;
    opacity: 0;
    z-index: 99;
  }

  @media (max-width: ${theme.breaks.large}) {
    span {
      height: 30px;
      width: 30px;
    }

    span:before {
      height: 30px;
      width: 30px;
    }
  }

  color: ${theme.colors.blue};
  font-size: ${theme.typography.md};
  font-weight: 500;
`;

const introSectionStyles = () => css`
  display: flex;
  margin-bottom: 90px;
`;

const chartSectionStyles = () => css`
  background-image: url(${chart});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-size: contain;
  height: 600px;
  margin-bottom: 90px;
`;

const otcSectionStyles = () => css`
  display: flex;
  position: relative;
  margin-bottom: 30px;
  align-items: center;
`;

const otcAssetLeftStyles = ({ theme }) => css`
  position: absolute;
  left: -120px;
  bottom: 0;

  @media (max-width: ${theme.breaks.large}) {
    display: none;
  }
`;

const otcTitleStyles = ({ theme }) => css`
  color: ${theme.colors.blue};
  opacity: 22%;
  font-size: 159px;
  letter-spacing: -6.84px;
  font-weight: bold;

  @media (max-width: ${theme.breaks.large}) {
    font-size: 100px;
  }
`;

const otcTitleBlueStyles = ({ theme }) => css`
  font-size: ${theme.typography.xs};
  color: ${theme.colors.lightblue};
  letter-spacing: 2.22px;
  font-weight: 500;
`;

const otcAlignStyles = ({ theme }) => css`
  margin-left: -50px;
  z-index: 1;

  @media (max-width: ${theme.breaks.large}) {
    margin-left: -30px;
  }
`;

const coursesSectionStyles = () => css`
  margin-bottom: 110px;
`;

const faqSectionStyles = () => css`
  margin-bottom: 30px;
`;

const containerCollapsedStyles = ({ theme }) => css`
  border-bottom: 1px solid #fff;
  display: flex;
  align-items: center;
  margin-bottom: ${theme.spacing.s1};
`;

const videoSectionStyles = ({ theme }) => css`
  height: 300px;
  width: 100%;
`;

const filterSectionStyles = () => css`
  display: flex;
  position: relative;
  margin-bottom: 110px;
  align-items: center;
`;

const titleStyles = ({ theme }) => css`
  color: ${theme.colors.white};
  line-height: 43px;
`;

const learnSectionStyles = ({ theme }) => css`
  margin: 75px 0 200px 0;

  @media (max-width: ${theme.breaks.medium1}) {
    margin: 75px 0 0 0;
  }
`;

const learnCardStyles = ({ theme }) =>
  css`
    padding-right: 80px;
    margin-bottom: 120px;

    @media (max-width: ${theme.breaks.medium}) {
      padding: 0;
    }
  `;

const wrapperWhyTitleStyles = () => css`
  display: flex;
  align-items: flex-start;
  margin-bottom: 22px;

  svg {
    margin-right: 16px;
  }
`;

const titleWhyStyles = ({ theme }) => css`
  color: ${theme.colors.lightblue};
  font-size: ${theme.typography.sm};
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 2.59px;
  width: 150px;
`;

const efficiencySectionStyles = () => css`
  margin-bottom: 150px;
`;

const consultancyImage = ({ theme }) => css`
  width: 45%;
  position: absolute;
  right: 0;
  top: 90px;
  height: 40rem;
  object-fit: cover;

  @media (max-width: ${theme.breaks.medium1}) {
    width: 100%;
    position: relative;
    padding-bottom: 12rem;
    height: auto;
  }
`;

const trailSectionStyles = ({ theme }) => css`
  position: relative;
  margin-bottom: 110px;

  @media (max-width: ${theme.breaks.medium1}) {
    margin-bottom: 100px;
  }
`;

const trailAssetRightStyles = ({ theme }) => css`
  position: absolute;
  right: 0;
  top: 0;
  height: 379px;

  @media (max-width: ${theme.breaks.huge}) {
    right: 0;
  }

  @media (max-width: ${theme.breaks.small}) {
    display: none;
  }
`;

export const EfficiencySection = styled('div')(efficiencySectionStyles);
export const Wrapper = styled('div')(wrapperStyles);
export const WrapperBanner = styled('div')(wrapperBannerStyles);
export const ContentBanner = styled('div')(contentBannerStyles);
export const MarginTextBanner = styled('div')(marginTextBannerStyles);
export const ImageBanner = styled('img')(imageBannerStyles);
export const MoreKnowledge = styled(Link)(moreKnowledgeStyles);
export const IntroSection = styled('section')(introSectionStyles);
export const ChartSection = styled('section')(chartSectionStyles);
export const OTCSection = styled('section')(otcSectionStyles);
export const OTCAssetLeft = styled('img')(otcAssetLeftStyles);
export const OTCTitle = styled('h1')(otcTitleStyles);
export const OTCTitleBlue = styled('h1')(otcTitleBlueStyles);
export const OTCAlign = styled('section')(otcAlignStyles);
export const CoursesSection = styled('section')(coursesSectionStyles);
export const FAQSection = styled('section')(faqSectionStyles);
export const ContainerCollapsed = styled('p')(containerCollapsedStyles);
export const VideoSection = styled('div')(videoSectionStyles);
export const FilterSection = styled('section')(filterSectionStyles);
export const TitleWhy = styled('h1')(titleStyles);
export const ItemTitleWhy = styled('h1')(titleWhyStyles);
export const LearnSection = styled('div')(learnSectionStyles);
export const LearnCard = styled('div')(learnCardStyles);
export const WrapperWhyTitle = styled('div')(wrapperWhyTitleStyles);
export const ConsultancyImage = styled('img')(consultancyImage);
export const TrailSection = styled('section')(trailSectionStyles);
export const TrailAssetRight = styled('img')(trailAssetRightStyles);

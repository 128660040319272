import styled from '@emotion/styled';
import { css } from '@emotion/react';

const imageStyles = ({ theme }) => css`
  width: 100%;
  max-width: 530px;
  object-fit: contain;

  @media (max-width: ${theme.breaks.medium1}) {
    margin: 30px 0;
  }
`;

const titleBigSectionStyles = ({ theme }) => css`
  color: ${theme.colors.background};
  font-size: 56px;
  line-height: 57px;

  @media (max-width: ${theme.breaks.large}) {
    font-size: ${theme.typography.xl3};
    line-height: 34px;
  }
`;

const textStyles = ({ theme }) => css`
  font-size: ${theme.typography.sm};
  color: ${theme.colors.background};
  line-height: 24px;
  font-weight: 600;
`;

export const Image = styled('img')(imageStyles);
export const TitleBig = styled('h1')(titleBigSectionStyles);
export const Text = styled('p')(textStyles);

import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const wrapperStyles = () => css`
  position: relative;
`;

const headerStyles = ({ theme, active }) => css`
  margin-bottom: 8px;
  ${'' /* margin-left: 40px; */}
  border-radius: 4px;
  opacity: 1;
  position: relative;

  opacity: ${active ? 1 : 0.5};
  transition: opacity 0.5s ease-out;

  @media (min-width: ${theme.breaks.medium1}) {
    cursor: pointer;
  }
`;

const titleAskStyles = ({ theme }) => css`
  font-size: ${theme.typography.md1};
  color: ${theme.colors.white};
  font-weight: 500;
  margin-bottom: 8px;
`;

const askStyles = ({ theme }) => css`
  display: flex;
  align-items: center;

  position: relative;

  padding: 20px 0 20px 15px;
  margin-bottom: 30px;

  svg {
    color: ${theme.colors.white};
  }

  @media (max-width: ${theme.breaks.medium1}) {
    align-items: flex-start;
  }
`;

const answerStyles = () => css`
  padding: 0 24px 24px;
`;

const containerStyles = () => css`
  position: absolute;
`;

const cardStyles = ({ active }) => css`
  background: linear-gradient(180deg, #3a4547, rgba(18, 18, 20, 0));

  border-radius: 8px;
  padding: 30px;

  width: 100%;
  height: auto;
`;

const imageStyles = () => css`
  width: 100%;
  border-radius: 8px;

  margin-bottom: 3rem;
`;

const depositionNameStyles = ({ theme, color }) => css`
  font-size: ${theme.typography.sm};
  color: ${color};
  font-weight: bold;

  svg {
    margin-left: 16px;
  }
`;

const alignCenterStyles = () => css`
  display: flex;
  align-items: center;
`;

const depositionInfoTextStyles = ({ theme }) => css`
  font-size: 12px;
  color: ${theme.colors.white};
  font-weight: 100;
  opacity: 60%;
  margin: 5px 0;
`;

const depositionCardStyles = ({ theme }) => css`
  display: flex;

  img {
    border-radius: 50%;
    width: 68px;
    height: 68px;
    object-fit: cover;
    margin-right: 34px;
  }

  svg {
    transition: all 0.3s cubic-bezier(0.25, 0.39, 0.39, 2.01);
  }

  svg:hover {
    cursor: pointer;
    transform: scale(1.2);
    transition: all 0.3s cubic-bezier(0.25, 0.39, 0.39, 2.01);
  }
`;

const numberStyles = ({ theme, color, noline }) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 30px;
  position: relative;

  background: #111;
  width: 50px;
  height: 50px;

  span {
    position: absolute;
    font-size: 60px;
    left: 25px;
    top: 0px;
    color: ${color};
    font-weight: 600;

    text-shadow: 0px 3px 6px #00000029;
  }

  ${!noline &&
  css`
    &:after {
      content: '';
      position: absolute;
      width: 2px;
      right: 25px;
      top: 70px;
      height: 34px;
      background: #707070;
    }

    @media (max-width: ${theme.breaks.medium1}) {
      &:after {
        display: none;
      }
    }
  `}
`;

const sectionStyles = ({ theme }) => css`
  margin: 30px 0 100px 0;
  min-height: 925px;

  @media (max-width: ${theme.breaks.medium1}) {
    margin: 30px 0 0 0;
  }
`;

const subtitleStyles = ({ theme, type, color }) => css`
  font-size: ${theme.typography.xs};
  color: ${color};

  letter-spacing: 2.22px;
  font-weight: 500;
`;

const seeMoreStyles = ({ theme, color }) => css`
  font-size: ${theme.typography.xs};
  color: ${color};

  font-weight: 500;

  &:hover {
    text-decoration: underline;
  }
`;

export const Wrapper = styled('div')(wrapperStyles);
export const Header = styled(motion.div)(headerStyles);
export const TitleAsk = styled('h1')(titleAskStyles);
export const Ask = styled('div')(askStyles);
export const Answer = styled('div')(answerStyles);
export const Container = styled('div')(containerStyles);
export const Card = styled('div')(cardStyles);
export const Image = styled('img')(imageStyles);

export const DepositionName = styled('h1')(
  depositionNameStyles,
  alignCenterStyles
);
export const DepositionInfoText = styled('h1')(depositionInfoTextStyles);
export const DepositionCard = styled('div')(depositionCardStyles);
export const NumberWrapper = styled('div')(numberStyles);
export const Section = styled('section')(sectionStyles);
export const SubTitle = styled('div')(subtitleStyles);
export const SeeMore = styled(Link)(seeMoreStyles);

import React, {
  createContext,
  useState,
  useContext,
  useCallback,
  useEffect,
} from 'react';
import api from '~/services/api';

const CoursesContext = createContext();

const CoursesProvider = ({ children }) => {
  const [otcCourses, setOtcCourses] = useState([]);

  const loadCourses = useCallback(async (id) => {
    try {
      const responseCourses = await api.get('courses/', {
        params: {
          category: id || null,
        },
      });

      setOtcCourses([...responseCourses.data]);
    } catch {
      setOtcCourses([]);
    }
  }, []);

  useEffect(() => {
    loadCourses();
  }, [loadCourses]);

  const qtdCourses = (modules) => {
    const array = modules.map((modl) => modl.lessons.length);
    let sum = 0;

    for (let i = 0; i < array.length; i += 1) {
      sum += array[i];
    }
    return sum;
  };

  return (
    <CoursesContext.Provider value={{ loadCourses, otcCourses, qtdCourses }}>
      {children}
    </CoursesContext.Provider>
  );
};

function useCoursesContext() {
  const context = useContext(CoursesContext);

  if (!context) {
    throw new Error('useCoursesContext must be used within an CoursesProvider');
  }
  return context;
}
export { CoursesProvider, useCoursesContext };
